/* Reactify Core Stylesheet

Table of Contents

00.Variables and Imports
01.Base
02.Buttons
03.Widths-Spacing
04.Borders
05.Badges
06.Breadcrumb
07.Tabs
08.React Siema Carousel
09.Navbar
10.Menu
11.Apps
12.Main
13.Cards
14.Dashboard
15.Calendar
16.Datatable
17.Alerts
18.Forms
19.Slider(Range)
20.Navs
21.Tooltip and popover
22.Progress
23.Rating
24.Sortable
25.Modal
26.Authorization
27.Icons
28.Floating Label
29.Context Menu
30. Timeline
*/

/* 00.Variables and Imports */
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700");

$main-menu-item-height: 230px;
$main-menu-width: 250px;
$main-menu-width-lg: 230px;
$main-menu-width-md: 230px;
$main-menu-width-xs: 230px;

$sub-menu-width: 300px;
$sub-menu-width-lg: 350px;
$sub-menu-width-md: 300px;
$sub-menu-width-xs: 190px;

$navbar-height: 120px;
$navbar-height-xs: 70px;
$navbar-height-md: 70px;
$navbar-height-lg: 70px;

$main-margin: 60px;
$main-margin-lg: 50px;
$main-margin-md: 40px;
$main-margin-xs: 15px;

$emails-menu-width: 280px;
$emails-menu-transform: -280px;

$main-menu-item-height-mobile: 90px;

$menu-collapse-time: 300ms;
$animation-time-long: 1000ms;
$animation-time-short: 200ms;

$border-radius: 0.1rem;

$reactify-theme-color-purple: #922c88;
$reactify-theme-color-blue: #145388;
$reactify-theme-color-green: #576a3d;
$reactify-theme-color-orange: #e2863b;
$reactify-theme-color-red: #880a1f;

$info-color: #3195a5;
$success-color: #4aad62;
$warning-color: #b69329;
$error-color: #dc3143;

/* 01.Base */
html {
  width: 100%;
  height: 100%;
  background: $reactify-background-color;
}

:root {
  --reactify-theme-color-1: #{$reactify-theme-color-1};
  --reactify-theme-color-2: #{$reactify-theme-color-2};
  --reactify-theme-color-3: #{$reactify-theme-color-3};
  --reactify-theme-color-4: #{$reactify-theme-color-4};
  --reactify-theme-color-5: #{$reactify-theme-color-5};
  --reactify-theme-color-6: #{$reactify-theme-color-6};
  --reactify-theme-color-1-10: #{rgba($reactify-theme-color-1, 0.1)};
  --reactify-theme-color-2-10: #{rgba($reactify-theme-color-2, 0.1)};
  --reactify-theme-color-3-10: #{rgba($reactify-theme-color-3, 0.1)};
  --reactify-theme-color-4-10: #{rgba($reactify-theme-color-4, 0.1)};
  --reactify-theme-color-5-10: #{rgba($reactify-theme-color-5, 0.1)};
  --reactify-theme-color-6-10: #{rgba($reactify-theme-color-6, 0.1)};

  --primary-color: #{$primary-color};
  --reactify-foreground-color: #{$reactify-foreground-color};
  --reactify-separator-color: #{$reactify-separator-color};
}

#root {
  height: 100%;
}

body {
  font-family: "Nunito", sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  color: $primary-color;
  background: $reactify-background-color;

  &.background {
    height: 100%;

    main {
      margin: 0 !important;
      height: 100%;

      .container {
        height: 100%;
      }
    }
  }
}

h1 {
  font-size: 1.75rem;
  padding-bottom: 10px;
  display: inline-block;

  @include respond-below(sm) {
    font-size: 1.3rem;
    padding-top: 0.5rem;
  }
}

h2 {
  font-size: 1.4rem;

  @include respond-below(sm) {
    font-size: 1.1rem;
  }
}

h3 {
  font-size: 1.3rem;

  @include respond-below(sm) {
    font-size: 1rem;
  }
}

h3 {
  font-size: 1.2rem;

  @include respond-below(sm) {
    font-size: 1rem;
  }
}

h4 {
  font-size: 1.15rem;

  @include respond-below(sm) {
    font-size: 0.9rem;
  }
}

h5 {
  font-size: 1.1rem;

  @include respond-below(sm) {
    font-size: 0.9rem;
  }
}

h6 {
  font-size: 1rem;

  @include respond-below(sm) {
    font-size: 0.85rem;
  }
}

hr {
  border-top: 1px solid $reactify-separator-color;
}

.disable-text-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

::selection {
  background: lighten($reactify-theme-color-3, 20%);
}

::-moz-selection {
  background: lighten($reactify-theme-color-3, 20%);
}

.ml-29px {
  margin-left: 30px !important;
}
.font-50px {
  font-size: 50px;
}
.scrollbar-container {
  margin-right: -15px;
  padding-right: 15px;
  position: relative;
}

.ps__rail-y {
  width: 5px;
}

.ps__thumb-y {
  left: 0;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  width: 5px;
  left: 0;
}

.ps__thumb-y {
  background-color: $reactify-separator-color;
}

.video-play-icon {
  width: 100%;
  height: 100%;
  position: absolute;

  span {
    position: absolute;
    background: rgba(255, 255, 255, 0.7);
    height: 1.25em;
    border-radius: 0.75em;
    line-height: 0.65em;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.75em;
    width: 2em;
    text-align: center;

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 0 8px 12px;
      font-size: 0;
      border-color: transparent transparent transparent $reactify-theme-color-1;
    }
  }

  &:hover,
  &:active {
    span {
      background: rgba(255, 255, 255, 0.85);
    }
  }
}
svg {
  fill: $reactify-theme-color-1;
}
.recharts-line path {
  stroke: $reactify-theme-color-1 !important;
}
.recharts-line-dots circle {
  stroke: $reactify-theme-color-1 !important;
}
.recharts-tooltip-item {
  color: $reactify-theme-color-1 !important;
}
.svg-override {
  width: 28px;
  height: 28px;
  margin: 14px 0 0 10px;
  background: #f7f7f7;
  padding: 5px;
}
// ReChart
.recharts-legend-wrapper {
  width: auto !important;
  left: 0 !important;
}
.recharts-pie-label-text {
  font-weight: bold;
  font-size: 18px;
}
.chartposition .recharts-surface {
  max-width: 170px !important;
  height: 170px !important;
}
.pie-mini-minHeight {
  min-height: 257px;
}
.pie-row .pie-wrap > div {
  background: red;
  margin: 0 auto;
}
/* Thunder Bolt Animation */
.thunder-cloud .bolt {
  animation: flash 2s infinite;
}

@keyframes flash {
  0% {
    transform: translateY(-25px) translateX(25px) scaleY(0.95);
    opacity: 0;
  }
  5%,
  25% {
    opacity: 1;
  }
  15%,
  20% {
    opacity: 0;
  }
  50% {
    transform: translateY(8px) translateX(-10px);
    opacity: 1;
  }
  80% {
    transform: translateY(8px) translateX(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(-25px) translateX(25px) scaleY(0.95);
    opacity: 0;
  }
}
// Dropcaps
.text-dropcap {
  font-size: 50px;
  font-weight: bold;
  margin-right: 5px;
}

/* Raindrops Animation */
.rain-cloud .raindrop-one,
.rain-cloud .raindrop-two,
.rain-cloud .raindrop-three {
  opacity: 0;
  animation-timing-function: cubic-bezier(1, 0, 1, 1);
}

.rain-cloud .raindrop-one {
  animation: falling-drop-one 2s infinite;
}

.rain-cloud .raindrop-two {
  animation: falling-drop-two 1.9s infinite;
  animation-delay: 0.8s;
}

.rain-cloud .raindrop-three {
  animation: falling-drop-three 1.8s infinite;
  animation-delay: 0.5s;
}

@keyframes falling-drop-one {
  0% {
    transform: translateY(-35px);
    opacity: 0;
  }
  10% {
    transform: translateY(-15px);
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  60% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(-35px);
    opacity: 0;
  }
}

@keyframes falling-drop-two {
  0% {
    transform: translateY(-105px);
    opacity: 0;
  }
  10% {
    transform: translateY(-85px);
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  60% {
    transform: translateY(5px);
    opacity: 0;
  }
  100% {
    transform: translateY(-105px);
    opacity: 0;
  }
}

@keyframes falling-drop-three {
  0% {
    transform: translateY(-105px);
    opacity: 0;
  }
  10% {
    transform: translateY(-85px);
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  60% {
    transform: translateY(35px);
    opacity: 0;
  }
  100% {
    transform: translateY(-105px);
    opacity: 0;
  }
}

/* Snowflake Animations */
.snow-cloud .snowflake-one,
.snow-cloud .snowflake-two,
.snow-cloud .snowflake-three {
  opacity: 0;
  transform-origin: center center;
  animation-timing-function: ease-in;
}

.snow-cloud .snowflake-one {
  animation: falling-snow-one 4s infinite;
}

.snow-cloud .snowflake-two {
  animation: falling-snow-two 3.8s infinite;
  animation-delay: 2.5s;
}

.snow-cloud .snowflake-three {
  animation: falling-snow-three 3.9s infinite;
  animation-delay: 1.5s;
}

@keyframes falling-snow-one {
  0% {
    transform: translateY(-65px) rotate(0);
    opacity: 0;
  }
  20% {
    transform: translateY(-10px) translateX(30px) rotate(-30deg);
    opacity: 1;
  }
  40% {
    transform: translateY(45px) translateX(-30px) rotate(30deg);
  }
  50% {
    opacity: 1;
  }
  60% {
    transform: translateY(100px) translateX(30px) rotate(-30deg);
    opacity: 0;
  }
  100% {
    transform: translateY(-65px);
    opacity: 0;
  }
}

@keyframes falling-snow-two {
  0% {
    transform: translateY(-75px) rotate(0);
    opacity: 0;
  }
  20% {
    transform: translateY(-45px) translateX(40px) rotate(-30deg);
    opacity: 1;
  }
  40% {
    transform: translateY(5px) translateX(-40px) rotate(30deg);
  }
  50% {
    opacity: 1;
  }
  60% {
    transform: translateY(30px) translateX(20px) rotate(-30deg);
    opacity: 0;
  }
  100% {
    transform: translateY(-75px);
    opacity: 0;
  }
}

@keyframes falling-snow-three {
  0% {
    transform: translateY(-85px) rotate(0);
    opacity: 0;
  }
  20% {
    transform: translateY(-10px) translateX(-30px) rotate(30deg);
    opacity: 1;
  }
  40% {
    transform: translateY(25px) translateX(30px) rotate(-30deg);
  }
  50% {
    opacity: 1;
  }
  60% {
    transform: translateY(60px) translateX(-30px) rotate(30deg);
    opacity: 0;
  }
  100% {
    transform: translateY(-85px);
    opacity: 0;
  }
}

/* Sunny Cloud Animations */
.sun-cloud .sun-half {
  animation: sun-grow 4s infinite cubic-bezier(0.2, 0.85, 0.4, 1.5);
  transform-origin: bottom center;
}

.sun-cloud .ray-one {
  animation: ray-show-one 4s infinite linear;
  transform-origin: center right;
}
.sun-cloud .ray-two {
  animation: ray-show-two 4s infinite linear;
  transform-origin: bottom right;
}
.sun-cloud .ray-three {
  animation: ray-show-three 4s infinite linear;
  transform-origin: bottom center;
}
.sun-cloud .ray-four {
  animation: ray-show-four 4s infinite linear;
  transform-origin: bottom left;
}
.sun-cloud .ray-five {
  animation: ray-show-five 4s infinite linear;
  transform-origin: center left;
}

@keyframes sun-grow {
  0%,
  90%,
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
  15%,
  80% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes ray-show-one {
  0%,
  15%,
  80%,
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
  20%,
  70% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes ray-show-two {
  0%,
  20%,
  80%,
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
  25%,
  70% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes ray-show-three {
  0%,
  25%,
  80%,
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
  30%,
  70% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes ray-show-four {
  0%,
  30%,
  80%,
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
  35%,
  70% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes ray-show-five {
  0%,
  35%,
  80%,
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
  40%,
  70% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes ray-show-six {
  0%,
  40%,
  80%,
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
  45%,
  70% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes ray-show-seven {
  0%,
  45%,
  80%,
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
  50%,
  70% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes ray-show-eight {
  0%,
  50%,
  80%,
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
  55%,
  70% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Sunshine Animation */
/* If only using this animation be sure to grab the sun-grow and ray-show-x keyframes set above */
.sunshine .sun-full {
  animation: sun-grow 4s infinite cubic-bezier(0.2, 0.85, 0.4, 1.5);
  transform-origin: center center;
}

.sunshine .sun-ray-one {
  animation: ray-show-one 4s infinite ease-in;
  transform-origin: center right;
}

.sunshine .sun-ray-two {
  animation: ray-show-two 4s infinite ease-in;
  transform-origin: bottom right;
}

.sunshine .sun-ray-three {
  animation: ray-show-three 4s infinite ease-in;
  transform-origin: bottom center;
}

.sunshine .sun-ray-four {
  animation: ray-show-four 4s infinite ease-in;
  transform-origin: bottom left;
}

.sunshine .sun-ray-five {
  animation: ray-show-five 4s infinite ease-in;
  transform-origin: center left;
}

.sunshine .sun-ray-six {
  animation: ray-show-six 4s infinite ease-in;
  transform-origin: top left;
}

.sunshine .sun-ray-seven {
  animation: ray-show-seven 4s infinite ease-in;
  transform-origin: top center;
}

.sunshine .sun-ray-eight {
  animation: ray-show-eight 4s infinite ease-in;
  transform-origin: top right;
}

/* Windy Cloud Animation */
.windy-cloud .cloud-wrap {
  animation: bob 2s infinite cubic-bezier(0, 0, 0.5, 1.5);
}
.windy-cloud .cloud {
  animation: cloud-push 4s infinite;
  transform-origin: left center;
}
.windy-cloud .wind-one {
  animation: wind-slide-one 4s infinite;
}
.windy-cloud .wind-two {
  animation: wind-slide-two 4s infinite;
}
.windy-cloud .wind-three {
  animation: wind-slide-three 4s infinite;
}

@keyframes bob {
  0%,
  100% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes cloud-push {
  0%,
  80%,
  100% {
    transform: translateX(-120px) scale(1.2);
  }
  10%,
  60% {
    transform: translateX(0) scale(1);
  }
}

@keyframes wind-slide-one {
  0%,
  70%,
  100% {
    transform: scaleX(0);
    opacity: 0;
  }
  10%,
  60% {
    transform: scaleX(1);
    opacity: 1;
  }
}

@keyframes wind-slide-two {
  0%,
  70%,
  100% {
    transform: scaleX(0);
    opacity: 0;
  }
  10%,
  60% {
    transform: scaleX(1);
    opacity: 1;
  }
}

@keyframes wind-slide-three {
  0%,
  70%,
  100% {
    transform: scaleX(0);
    opacity: 0;
  }
  10%,
  60% {
    transform: scaleX(1);
    opacity: 1;
  }
}

.font-color-1 {
  color: $reactify-theme-color-1 !important;
}
.font-color-2 {
  color: $reactify-theme-color-2 !important;
}
.font-color-3 {
  color: $reactify-theme-color-3 !important;
}
.font-color-4 {
  color: $success-color !important;
}
.font-color-5 {
  color: $error-color !important;
}
.font-color-6 {
  color: $warning-color !important;
}
.twitter-color {
  color: #5bc0de !important;
}
.font-30px {
  font-size: 30px;
}
.w-250-px {
  width: 250px;
}
.bg-404 {
  background: url("/assets/img/404.png") no-repeat center top;
  background-size: cover;
  min-height: 600px;
}
.list-item-heading {
  font-size: 1rem;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.no-transition {
  transition: none !important;
}

.display-1 {
  @include respond-below(sm) {
    font-size: 2.8rem;
  }
}

.display-2 {
  @include respond-below(sm) {
    font-size: 2.6rem;
  }
}

.display-3 {
  @include respond-below(sm) {
    font-size: 2.4rem;
  }
}

.display-4 {
  @include respond-below(sm) {
    font-size: 2.2rem;
  }
}

.lead {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 2rem;

  @include respond-below(sm) {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }
}

a {
  color: $primary-color;
  transition: color $animation-time-short;

  &:hover,
  &:active {
    text-decoration: initial;
    color: $reactify-theme-color-1;
  }
}

p {
  font-size: 0.85rem;
  line-height: 1.3rem;
  font-family: "Nunito", sans-serif;
}

.text-large {
  font-size: 1.9rem !important;
}

.text-one {
  font-size: 1rem !important;
}

.text-xlarge {
  font-size: 2.7rem !important;
}

.text-small {
  font-size: 0.76rem;
  line-height: 0.9rem;
}

.text-white {
  color: $reactify-btn-text-color !important;
}

.text-extra-small {
  font-size: 0.6rem;
}

.text-default {
  color: $primary-color !important;
}

.text-muted {
  color: $muted-color !important;
}

.text-semi-muted {
  color: $secondary-color !important;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-semibold {
  font-weight: 600;
}

.color-theme-1 {
  color: $reactify-theme-color-1;
}

.font-color {
  color: $reactify-theme-color-1;
}
.color-theme-2 {
  color: $reactify-theme-color-2;
}
.color-theme-3 {
  color: $reactify-theme-color-3;
}
.color-theme-4 {
  color: $reactify-theme-color-4;
}

.view-icon {
  font-size: 20px;
  color: $secondary-color;

  &:hover {
    color: $reactify-theme-color-1;
  }

  &.s {
    font-size: 18px;
  }
}

#displayOptions {
  a {
    cursor: pointer;
  }

  a.active i {
    color: $reactify-theme-color-1;
  }

  button {
    border-color: $secondary-color;
    color: $secondary-color;

    &:hover {
      background-color: $reactify-theme-color-1;
      border-color: $reactify-theme-color-1;
      color: $reactify-btn-text-color;
    }
  }

  .btn-outline-dark:not(:disabled):not(.disabled):active,
  .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    background-color: $reactify-theme-color-1;
    border-color: $reactify-theme-color-1;
    color: $reactify-btn-text-color;
  }

  .view-icon {
    svg {
      width: 19px;
    }

    .view-icon-svg {
      fill: $secondary-color;
    }

    &:hover,
    &.active {
      .view-icon-svg {
        fill: $reactify-theme-color-1;
      }
    }
  }
}

.text-theme-2 {
  color: $reactify-theme-color-2 !important;
}

.text-theme-3 {
  color: $reactify-theme-color-3 !important;
}

.text-primary,
.text-theme-1 {
  color: $reactify-theme-color-1 !important;
}

.text-secondary {
  color: $primary-color !important;
}

.main-heading {
  border-bottom: 1px solid $reactify-separator-color;
}

.separator {
  border-bottom: 1px solid $reactify-separator-color;
}

.alert-dismissible .close {
  padding: 0.5rem 1.25rem;
}

.rounded {
  border-radius: 50px !important;
}
.h-155 {
  height: 155px;
}
.zero-radius {
  border-radius: 0 !important;
}
.img-thumbnail {
  border-radius: $border-radius;
  padding: 0;
  border: initial;
}

.list-thumbnail {
  border-radius: $border-radius;
  padding: 0;
  border: initial;
  height: auto;
  max-width: unset;
  height: 85px;
  object-fit: cover;
  width: unset !important;

  @include respond-below(md) {
    height: 80px;
  }

  @include respond-below(xs) {
    height: 70px;
  }

  &.responsive {
    @include respond-below(md) {
      width: unset;
      height: 136px;
    }

    @include respond-below(xs) {
      width: 110px !important;
      height: 100%;
    }

    @include respond-below(xxs) {
      width: 90px !important;
      height: 100%;
    }
  }

  &.small {
    height: 60px;
    font-size: 1rem;

    @include respond-below(md) {
      height: 55px;
    }

    @include respond-below(xs) {
      height: 50px;
    }
  }

  &.xsmall {
    height: 40px;
    font-size: 1rem;

    @include respond-below(md) {
      height: 40px;
    }

    @include respond-below(xs) {
      height: 40px;
    }
  }
}

.list-thumbnail-letters {
  width: 85px;
  height: 85px;
  background: $reactify-theme-color-1;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  color: $reactify-btn-text-color;

  @include respond-below(md) {
    width: 80px;
    height: 80px;
  }

  @include respond-below(xs) {
    width: 70px;
    height: 70px;
  }

  &.small {
    width: 60px;
    height: 60px;
    font-size: 1rem;

    @include respond-below(md) {
      width: 55px;
      height: 55px;
    }

    @include respond-below(xs) {
      width: 50px;
      height: 50px;
    }
  }
}

.white {
  color: $reactify-btn-text-color !important;
}

/* 02.Buttons */

button {
  color: $primary-color;
  outline: initial !important;
}
.btn.btn-android {
  border: 1px solid #a4c639 !important;
  background-color: #fff !important;
  color: #a4c639;
}
.btn.btn-android:hover,
.btn.btn-android:focus {
  background-color: #a4c639 !important;
  border-color: #a4c639 !important;
  color: #fff;
}

.btn.btn-bitcoin {
  border: 1px solid #eb9737 !important;
  background-color: #fff !important;
  color: #eb9737;
}
.btn.btn-bitcoin:hover,
.btn.btn-bitcoin:focus {
  background-color: #eb9737 !important;
  border-color: #eb9737 !important;
  color: #fff;
}

.btn.btn-bitbucket {
  border: 1px solid #205081 !important;
  background-color: #fff !important;
  color: #205081;
}
.btn.btn-bitbucket:hover,
.btn.btn-bitbucket:focus {
  background-color: #205081 !important;
  border-color: #205081 !important;
  color: #fff;
}

.btn.btn-dribbble {
  border: 1px solid #ea4c89 !important;
  background-color: #fff !important;
  color: #ea4c89;
}
.btn.btn-dribbble:hover,
.btn.btn-dribbble:focus {
  background-color: #ea4c89 !important;
  border-color: #ea4c89 !important;
  color: #fff;
}
.btn.btn-dropbox {
  border: 1px solid #007ee5 !important;
  background-color: #fff !important;
  color: #007ee5;
}
.btn.btn-dropbox:hover,
.btn.btn-dropbox:focus {
  background-color: #007ee5 !important;
  border-color: #007ee5 !important;
  color: #fff;
}
.btn.btn-facebook {
  border: 1px solid #4863ae !important;
  background-color: #fff !important;
  color: #4863ae;
}
.btn.btn-facebook:hover,
.btn.btn-facebook:focus {
  background-color: #4863ae !important;
  border-color: #4863ae !important;
  color: #fff;
}
.btn.btn-flickr {
  border: 1px solid #ff0084 !important;
  background-color: #fff !important;
  color: #ff0084;
}
.btn.btn-flickr:hover,
.btn.btn-flickr:focus {
  background-color: #ff0084 !important;
  border-color: #ff0084 !important;
  color: #fff;
}
.btn.btn-foursquare {
  border: 1px solid #0072b1 !important;
  background-color: #fff !important;
  color: #0072b1;
}
.btn.btn-foursquare:hover,
.btn.btn-foursquare:focus {
  background-color: #0072b1 !important;
  border-color: #0072b1 !important;
  color: #fff;
}
.btn.btn-googleplus {
  border: 1px solid #dd4a38 !important;
  background-color: #fff !important;
  color: #dd4a38;
}
.btn.btn-googleplus:hover,
.btn.btn-googleplus:focus {
  background-color: #dd4a38 !important;
  border-color: #dd4a38 !important;
  color: #fff;
}
.btn.btn-html5 {
  border: 1px solid #ff3617 !important;
  background-color: #fff !important;
  color: #ff3617;
}
.btn.btn-html5:hover,
.btn.btn-html5:focus {
  background-color: #ff3617 !important;
  border-color: #ff3617 !important;
  color: #fff;
}
.btn.btn-instagram {
  border: 1px solid #3f729b !important;
  background-color: #fff !important;
  color: #3f729b;
}
.btn.btn-instagram:hover,
.btn.btn-instagram:focus {
  background-color: #3f729b !important;
  border-color: #3f729b !important;
  color: #fff;
}
.btn.btn-linkedin {
  border: 1px solid #0083a8 !important;
  background-color: #fff !important;
  color: #0083a8;
}
.btn.btn-linkedin:hover,
.btn.btn-linkedin:focus {
  background-color: #0083a8 !important;
  border-color: #0083a8 !important;
  color: #fff;
}
.btn.btn-pinterest {
  border: 1px solid #c91618 !important;
  background-color: #fff !important;
  color: #c91618;
}
.btn.btn-pinterest:hover,
.btn.btn-pinterest:focus {
  background-color: #c91618 !important;
  border-color: #c91618 !important;
  color: #fff;
}
.btn.btn-rss {
  border: 1px solid #ff7f25 !important;
  background-color: #fff !important;
  color: #ff7f25;
}
.btn.btn-rss:hover,
.btn.btn-rss:focus {
  background-color: #ff7f25 !important;
  border-color: #ff7f25 !important;
  color: #fff;
}
.btn.btn-skype {
  border: 1px solid #00a2ed !important;
  background-color: #fff !important;
  color: #00a2ed;
}
.btn.btn-skype:hover,
.btn.btn-skype:focus {
  background-color: #00a2ed !important;
  border-color: #00a2ed !important;
  color: #fff;
}
.btn.btn-stackoverflow {
  border: 1px solid #f18436 !important;
  background-color: #fff !important;
  color: #f18436;
}
.btn.btn-stackoverflow:hover,
.btn.btn-stackoverflow:focus {
  background-color: #f18436 !important;
  border-color: #f18436 !important;
  color: #fff;
}
.btn.btn-tumblr {
  border: 1px solid #374a61 !important;
  background-color: #fff !important;
  color: #374a61;
}
.btn.btn-tumblr:hover,
.btn.btn-tumblr:focus {
  background-color: #374a61 !important;
  border-color: #374a61 !important;
  color: #fff;
}
.btn.btn-twitter {
  border: 1px solid #46c0fb !important;
  background-color: #fff !important;
  color: #46c0fb;
}
.btn.btn-twitter:hover,
.btn.btn-twitter:focus {
  background-color: #46c0fb !important;
  border-color: #46c0fb !important;
  color: #fff;
}
.btn.btn-vk {
  border: 1px solid #45688e !important;
  background-color: #fff !important;
  color: #45688e;
}
.btn.btn-vk:hover,
.btn.btn-vk:focus {
  background-color: #45688e !important;
  border-color: #45688e !important;
  color: #fff;
}
.btn.btn-weibo {
  border: 1px solid #d7584d !important;
  background-color: #fff !important;
  color: #d7584d;
}
.btn.btn-weibo:hover,
.btn.btn-weibo:focus {
  background-color: #d7584d !important;
  border-color: #d7584d !important;
  color: #fff;
}
.btn.btn-windows {
  border: 1px solid #48bbef !important;
  background-color: #fff !important;
  color: #48bbef;
}
.btn.btn-windows:hover,
.btn.btn-windows:focus {
  background-color: #48bbef !important;
  border-color: #48bbef !important;
  color: #fff;
}
.btn.btn-xing {
  border: 1px solid #0a5d5e !important;
  background-color: #fff !important;
  color: #0a5d5e;
}
.btn.btn-xing:hover,
.btn.btn-xing:focus {
  background-color: #0a5d5e !important;
  border-color: #0a5d5e !important;
  color: #fff;
}
.btn.btn-youtube {
  border: 1px solid #ff0000 !important;
  background-color: #fff !important;
  color: #ff0000;
}
.btn.btn-youtube:hover,
.btn.btn-youtube:focus {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
  color: #fff;
}
.btn.btn-appnet {
  border: 1px solid #3178bd !important;
  background-color: #fff !important;
  color: #3178bd;
}
.btn.btn-appnet:hover,
.btn.btn-appnet:focus {
  background-color: #3178bd !important;
  border-color: #3178bd !important;
  color: #fff;
}
.btn.btn-blogger {
  border: 1px solid #ee5a22 !important;
  background-color: #fff !important;
  color: #ee5a22;
}
.btn.btn-blogger:hover,
.btn.btn-blogger:focus {
  background-color: #ee5a22 !important;
  border-color: #ee5a22 !important;
  color: #fff;
}
.btn.btn-flattr {
  border: 1px solid #8aba42 !important;
  background-color: #fff !important;
  color: #8aba42;
}
.btn.btn-flattr:hover,
.btn.btn-flattr:focus {
  background-color: #8aba42 !important;
  border-color: #8aba42 !important;
  color: #fff;
}
.btn.btn-github {
  border: 1px solid #171515 !important;
  background-color: #fff !important;
  color: #171515;
}
.btn.btn-github:hover,
.btn.btn-github:focus {
  background-color: #171515 !important;
  border-color: #171515 !important;
  color: #fff;
}
.btn.btn-gowalla {
  border: 1px solid #ff720a !important;
  background-color: #fff !important;
  color: #ff720a;
}
.btn.btn-gowalla:hover,
.btn.btn-gowalla:focus {
  background-color: #ff720a !important;
  border-color: #ff720a !important;
  color: #fff;
}
.btn.btn-klout {
  border: 1px solid #e34a25 !important;
  background-color: #fff !important;
  color: #e34a25;
}
.btn.btn-klout:hover,
.btn.btn-klout:focus {
  background-color: #e34a25 !important;
  border-color: #e34a25 !important;
  color: #fff;
}
.btn.btn-lastfm {
  border: 1px solid #e34a25 !important;
  background-color: #fff !important;
  color: #e34a25;
}
.btn.btn-lastfm:hover,
.btn.btn-lastfm:focus {
  background-color: #e34a25 !important;
  border-color: #e34a25 !important;
  color: #fff;
}
.btn.btn-meetup {
  border: 1px solid #ff0026 !important;
  background-color: #fff !important;
  color: #ff0026;
}
.btn.btn-meetup:hover,
.btn.btn-meetup:focus {
  background-color: #ff0026 !important;
  border-color: #ff0026 !important;
  color: #fff;
}
.btn.btn-mysapce {
  border: 1px solid #000000 !important;
  background-color: #fff !important;
  color: #000000;
}
.btn.btn-mysapce:hover,
.btn.btn-mysapce:focus {
  background-color: #000000 !important;
  border-color: #000000 !important;
  color: #fff;
}
.btn.btn-quora {
  border: 1px solid #a82400 !important;
  background-color: #fff !important;
  color: #a82400;
}
.btn.btn-quora:hover,
.btn.btn-quora:focus {
  background-color: #a82400 !important;
  border-color: #a82400 !important;
  color: #fff;
}
.btn.btn-reddit {
  border: 1px solid #ff5700 !important;
  background-color: #fff !important;
  color: #ff5700;
}
.btn.btn-reddit:hover,
.btn.btn-reddit:focus {
  background-color: #ff5700 !important;
  border-color: #ff5700 !important;
  color: #fff;
}
.btn.btn-myspace {
  border: 1px solid #ff5700 !important;
  background-color: #fff !important;
  color: #ff5700;
}
.btn.btn-myspace:hover,
.btn.btn-myspace:focus {
  background-color: #ff5700 !important;
  border-color: #ff5700 !important;
  color: #fff;
}
.btn.btn-plurk {
  border: 1px solid #cf682f !important;
  background-color: #fff !important;
  color: #cf682f;
}
.btn.btn-plurk:hover,
.btn.btn-plurk:focus {
  background-color: #cf682f !important;
  border-color: #cf682f !important;
  color: #fff;
}
.btn.btn-smashing {
  border: 1px solid #ff4f27 !important;
  background-color: #fff !important;
  color: #ff4f27;
}
.btn.btn-smashing:hover,
.btn.btn-smashing:focus {
  background-color: #ff4f27 !important;
  border-color: #ff4f27 !important;
  color: #fff;
}
.btn.btn-soundcloud {
  border: 1px solid #ff4500 !important;
  background-color: #fff !important;
  color: #ff4500;
}
.btn.btn-soundcloud:hover,
.btn.btn-soundcloud:focus {
  background-color: #ff4500 !important;
  border-color: #ff4500 !important;
  color: #fff;
}
.btn.btn-viadeo {
  border: 1px solid #e7732e !important;
  background-color: #fff !important;
  color: #e7732e;
}
.btn.btn-viadeo:hover,
.btn.btn-viadeo:focus {
  background-color: #e7732e !important;
  border-color: #e7732e !important;
  color: #fff;
}
.btn.btn-vimeo {
  border: 1px solid #00a2cd !important;
  background-color: #fff !important;
  color: #00a2cd;
}
.btn.btn-vimeo:hover,
.btn.btn-vimeo:focus {
  background-color: #00a2cd !important;
  border-color: #00a2cd !important;
  color: #fff;
}
.btn.btn-amazon {
  border: 1px solid #f29e37 !important;
  background-color: #fff !important;
  color: #f29e37;
}
.btn.btn-amazon:hover,
.btn.btn-amazon:focus {
  background-color: #f29e37 !important;
  border-color: #f29e37 !important;
  color: #fff;
}
.btn.btn-delicious {
  border: 1px solid #3271cb !important;
  background-color: #fff !important;
  color: #3271cb;
}
.btn.btn-delicious:hover,
.btn.btn-delicious:focus {
  background-color: #3271cb !important;
  border-color: #3271cb !important;
  color: #fff;
}

.btn.btn-digg {
  border: 1px solid #164673 !important;
  background-color: #fff !important;
  color: #164673;
}
.btn.btn-digg:hover,
.btn.btn-digg:focus {
  background-color: #164673 !important;
  border-color: #164673 !important;
  color: #fff;
}
.btn.btn-disqus {
  border: 1px solid #2e9fff !important;
  background-color: #fff !important;
  color: #2e9fff;
}
.btn.btn-disqus:hover,
.btn.btn-disqus:focus {
  background-color: #2e9fff !important;
  border-color: #2e9fff !important;
  color: #fff;
}
.btn.btn-eventbrite {
  border: 1px solid #ff5616 !important;
  background-color: #fff !important;
  color: #ff5616;
}
.btn.btn-eventbrite:hover,
.btn.btn-eventbrite:focus {
  background-color: #ff5616 !important;
  border-color: #ff5616 !important;
  color: #fff;
}
.btn.btn-eventful {
  border: 1px solid #376095 !important;
  background-color: #fff !important;
  color: #376095;
}
.btn.btn-eventful:hover,
.btn.btn-eventful:focus {
  background-color: #376095 !important;
  border-color: #376095 !important;
  color: #fff;
}
.btn.btn-intensedebate {
  border: 1px solid #0099e1 !important;
  background-color: #fff !important;
  color: #0099e1;
}
.btn.btn-intensedebate:hover,
.btn.btn-intensedebate:focus {
  background-color: #0099e1 !important;
  border-color: #0099e1 !important;
  color: #fff;
}
.btn.btn-lanyrd {
  border: 1px solid #2e6ac2 !important;
  background-color: #fff !important;
  color: #2e6ac2;
}
.btn.btn-lanyrd:hover,
.btn.btn-lanyrd:focus {
  background-color: #2e6ac2 !important;
  border-color: #2e6ac2 !important;
  color: #fff;
}
.btn.btn-pinboard {
  border: 1px solid #0000ff !important;
  background-color: #fff !important;
  color: #0000ff;
}
.btn.btn-pinboard:hover,
.btn.btn-pinboard:focus {
  background-color: #0000ff !important;
  border-color: #0000ff !important;
  color: #fff;
}
.btn.btn-songkick {
  border: 1px solid #ff0050 !important;
  background-color: #fff !important;
  color: #ff0050;
}
.btn.btn-songkick:hover,
.btn.btn-songkick:focus {
  background-color: #ff0050 !important;
  border-color: #ff0050 !important;
  color: #fff;
}
.btn.btn-stumbleupon {
  border: 1px solid #eb4924 !important;
  background-color: #fff !important;
  color: #eb4924;
}
.btn.btn-stumbleupon:hover,
.btn.btn-stumbleupon:focus {
  background-color: #eb4924 !important;
  border-color: #eb4924 !important;
  color: #fff;
}
.btn.btn-gmail {
  border: 1px solid #db4038 !important;
  background-color: #fff !important;
  color: #db4038;
}
.btn.btn-gmail:hover,
.btn.btn-gmail:focus {
  background-color: #db4038 !important;
  border-color: #db4038 !important;
  color: #fff;
}
.btn.btn-google {
  border: 1px solid #4e6cf7 !important;
  background-color: #fff !important;
  color: #4e6cf7;
}
.btn.btn-google:hover,
.btn.btn-google:focus {
  background-color: #4e6cf7 !important;
  border-color: #4e6cf7 !important;
  color: #fff;
}
.btn.btn-evernote {
  border: 1px solid #6bb130 !important;
  background-color: #fff !important;
  color: #6bb130;
}
.btn.btn-evernote:hover,
.btn.btn-evernote:focus {
  background-color: #6bb130 !important;
  border-color: #6bb130 !important;
  color: #fff;
}
.btn.btn-grooveshark {
  border: 1px solid #e97a2f !important;
  background-color: #fff !important;
  color: #e97a2f;
}
.btn.btn-grooveshark:hover,
.btn.btn-grooveshark:focus {
  background-color: #e97a2f !important;
  border-color: #e97a2f !important;
  color: #fff;
}
.btn.btn-instapaper {
  border: 1px solid #222222 !important;
  background-color: #fff !important;
  color: #222222;
}
.btn.btn-instapaper:hover,
.btn.btn-instapaper:focus {
  background-color: #222222 !important;
  border-color: #222222 !important;
  color: #fff;
}
.btn.btn-itunes {
  border: 1px solid #525152 !important;
  background-color: #fff !important;
  color: #525152;
}
.btn.btn-itunes:hover,
.btn.btn-itunes:focus {
  background-color: #525152 !important;
  border-color: #525152 !important;
  color: #fff;
}
.btn.btn-opentable {
  border: 1px solid #990000 !important;
  background-color: #fff !important;
  color: #990000;
}
.btn.btn-opentable:hover,
.btn.btn-opentable:focus {
  background-color: #990000 !important;
  border-color: #990000 !important;
  color: #fff;
}
.btn.btn-logmein {
  border: 1px solid #000000 !important;
  background-color: #fff !important;
  color: #000000;
}
.btn.btn-logmein:hover,
.btn.btn-logmein:focus {
  background-color: #000000 !important;
  border-color: #000000 !important;
  color: #fff;
}
.btn.btn-ninetyninedesigns {
  border: 1px solid #072243 !important;
  background-color: #fff !important;
  color: #072243;
}
.btn.btn-ninetyninedesigns:hover,
.btn.btn-ninetyninedesigns:focus {
  background-color: #072243 !important;
  border-color: #072243 !important;
  color: #fff;
}
.btn.btn-paypal {
  border: 1px solid #32689a !important;
  background-color: #fff !important;
  color: #32689a;
}
.btn.btn-paypal:hover,
.btn.btn-paypal:focus {
  background-color: #32689a !important;
  border-color: #32689a !important;
  color: #fff;
}
.btn.btn-pocket {
  border: 1px solid #de5a5f !important;
  background-color: #fff !important;
  color: #de5a5f;
}
.btn.btn-pocket:hover,
.btn.btn-pocket:focus {
  background-color: #de5a5f !important;
  border-color: #de5a5f !important;
  color: #fff;
}
.btn.btn-scribd {
  border: 1px solid #231c1a !important;
  background-color: #fff !important;
  color: #231c1a;
}
.btn.btn-scribd:hover,
.btn.btn-scribd:focus {
  background-color: #231c1a !important;
  border-color: #231c1a !important;
  color: #fff;
}
.btn.btn-spotify {
  border: 1px solid #60af00 !important;
  background-color: #fff !important;
  color: #60af00;
}
.btn.btn-spotify:hover,
.btn.btn-spotify:focus {
  background-color: #60af00 !important;
  border-color: #60af00 !important;
  color: #fff;
}
.btn.btn-statusnet {
  border: 1px solid #829d25 !important;
  background-color: #fff !important;
  color: #829d25;
}
.btn.btn-statusnet:hover,
.btn.btn-statusnet:focus {
  background-color: #829d25 !important;
  border-color: #829d25 !important;
  color: #fff;
}
.btn.btn-stripe {
  border: 1px solid #2f7ed6 !important;
  background-color: #fff !important;
  color: #2f7ed6;
}
.btn.btn-stripe:hover,
.btn.btn-stripe:focus {
  background-color: #2f7ed6 !important;
  border-color: #2f7ed6 !important;
  color: #fff;
}
.btn.btn-yahoo {
  border: 1px solid #a200c2 !important;
  background-color: #fff !important;
  color: #a200c2;
}
.btn.btn-yahoo:hover,
.btn.btn-yahoo:focus {
  background-color: #a200c2 !important;
  border-color: #a200c2 !important;
  color: #fff;
}
.btn.btn-yelp {
  border: 1px solid #e60010 !important;
  background-color: #fff !important;
  color: #e60010;
}
.btn.btn-yelp:hover,
.btn.btn-yelp:focus {
  background-color: #e60010 !important;
  border-color: #e60010 !important;
  color: #fff;
}
.btn.btn-wikipedia {
  border: 1px solid #111111 !important;
  background-color: #fff !important;
  color: #111111;
}
.btn.btn-wikipedia:hover,
.btn.btn-wikipedia:focus {
  background-color: #111111 !important;
  border-color: #111111 !important;
  color: #fff;
}
.btn.btn-wordpress {
  border: 1px solid #464646 !important;
  background-color: #fff !important;
  color: #464646;
}
.btn.btn-wordpress:hover,
.btn.btn-wordpress:focus {
  background-color: #464646 !important;
  border-color: #464646 !important;
  color: #fff;
}
.btn.btn-ycombinator {
  border: 1px solid #ff6600 !important;
  background-color: #fff !important;
  color: #ff6600;
}
.btn.btn-ycombinator:hover,
.btn.btn-ycombinator:focus {
  background-color: #ff6600 !important;
  border-color: #ff6600 !important;
  color: #fff;
}
.btn.btn-appstore {
  border: 1px solid #000000 !important;
  background-color: #fff !important;
  color: #000000;
}
.btn.btn-appstore:hover,
.btn.btn-appstore:focus {
  background-color: #000000 !important;
  border-color: #000000 !important;
  color: #fff;
}
.btn.btn-googleplay {
  border: 1px solid #b9c13e !important;
  background-color: #fff !important;
  color: #b9c13e;
}
.btn.btn-googleplay:hover,
.btn.btn-googleplay:focus {
  background-color: #b9c13e !important;
  border-color: #b9c13e !important;
  color: #fff;
}
.btn.btn-macstore {
  border: 1px solid #007dcb !important;
  background-color: #fff !important;
  color: #007dcb;
}
.btn.btn-macstore:hover,
.btn.btn-macstore:focus {
  background-color: #007dcb !important;
  border-color: #007dcb !important;
  color: #fff;
}
.btn.btn-csgo {
  border: 1px solid #b67300 !important;
  background-color: #fff !important;
  color: #b67300;
}
.btn.btn-csgo:hover,
.btn.btn-csgo:focus {
  background-color: #b67300 !important;
  border-color: #b67300 !important;
  color: #fff;
}
.btn.btn-dota2 {
  border: 1px solid #c33d2b !important;
  background-color: #fff !important;
  color: #c33d2b;
}
.btn.btn-dota2:hover,
.btn.btn-dota2:focus {
  background-color: #c33d2b !important;
  border-color: #c33d2b !important;
  color: #fff;
}
.btn.btn-gm {
  border: 1px solid #1194f0 !important;
  background-color: #fff !important;
  color: #1194f0;
}
.btn.btn-gm:hover,
.btn.btn-gm:focus {
  background-color: #1194f0 !important;
  border-color: #1194f0 !important;
  color: #fff;
}
.btn.btn-lol {
  border: 1px solid #d79922 !important;
  background-color: #fff !important;
  color: #d79922;
}
.btn.btn-lol:hover,
.btn.btn-lol:focus {
  background-color: #d79922 !important;
  border-color: #d79922 !important;
  color: #fff;
}
.btn.btn-minecraft {
  border: 1px solid #94c864 !important;
  background-color: #fff !important;
  color: #94c864;
}
.btn.btn-minecraft:hover,
.btn.btn-minecraft:focus {
  background-color: #94c864 !important;
  border-color: #94c864 !important;
  color: #fff;
}
.btn.btn-tf2 {
  border: 1px solid #b35215 !important;
  background-color: #fff !important;
  color: #b35215;
}
.btn.btn-tf2:hover,
.btn.btn-tf2:focus {
  background-color: #b35215 !important;
  border-color: #b35215 !important;
  color: #fff;
}
.btn.btn-quake {
  border: 1px solid #cd1800 !important;
  background-color: #fff !important;
  color: #cd1800;
}
.btn.btn-quake:hover,
.btn.btn-quake:focus {
  background-color: #cd1800 !important;
  border-color: #cd1800 !important;
  color: #fff;
}
.btn.btn-wot {
  border: 1px solid #ff4005 !important;
  background-color: #fff !important;
  color: #ff4005;
}
.btn.btn-wot:hover,
.btn.btn-wot:focus {
  background-color: #ff4005 !important;
  border-color: #ff4005 !important;
  color: #fff;
}
.btn.btn-cloudapp {
  border: 1px solid #312c2a !important;
  background-color: #fff !important;
  color: #312c2a;
}
.btn.btn-cloudapp:hover,
.btn.btn-cloudapp:focus {
  background-color: #312c2a !important;
  border-color: #312c2a !important;
  color: #fff;
}
.btn.btn-creativecommons {
  border: 1px solid #000000 !important;
  background-color: #fff !important;
  color: #000000;
}
.btn.btn-creativecommons:hover,
.btn.btn-creativecommons:focus {
  background-color: #000000 !important;
  border-color: #000000 !important;
  color: #fff;
}
.btn.btn-ie {
  border: 1px solid #00a1d9 !important;
  background-color: #fff !important;
  color: #00a1d9;
}
.btn.btn-ie:hover,
.btn.btn-ie:focus {
  background-color: #00a1d9 !important;
  border-color: #00a1d9 !important;
  color: #fff;
}
.btn.btn-podcast {
  border: 1px solid #9365ce !important;
  background-color: #fff !important;
  color: #9365ce;
}
.btn.btn-podcast:hover,
.btn.btn-podcast:focus {
  background-color: #9365ce !important;
  border-color: #9365ce !important;
  color: #fff;
}
.btn.btn-steam {
  border: 1px solid #000000 !important;
  background-color: #fff !important;
  color: #000000;
}
.btn.btn-steam:hover,
.btn.btn-steam:focus {
  background-color: #000000 !important;
  border-color: #000000 !important;
  color: #fff;
}

.btn.btn-orange {
  background-color: #e67e22 !important;
  color: #fff;
  border: none;
}
.btn.btn-orange:hover {
  background-color: #d35400 !important;
  border-color: #d35400;
  color: #fff;
}
.btn.btn-turquoise {
  background-color: #1abc9c !important;
  color: #fff;
  border: none !important;
}
.btn.btn-turquoise:hover,
.btn.btn-turquoise:focus {
  background-color: #12957c !important;
  border-color: #12957c;
  color: #fff;
}
.btn.btn-green {
  background-color: #2ecc71 !important;
  color: #fff;
  border: none;
}
.btn.btn-green:hover {
  background-color: #27ae60 !important;
  border-color: #27ae60 !important;
  color: #fff;
}
.btn.btn-yellow {
  background-color: #f1c40f !important;
  color: #fff;
  border: none;
}
.btn.btn-yellow:hover,
.btn.btn-yellow:focus {
  background-color: #f39c12 !important;
  border-color: #f39c12 !important;
  color: #fff;
}
.btn.btn-purple:hover,
.btn.btn-purple:focus {
  background-color: #814fb5 !important;
  border-color: #814fb5 !important;
  color: #fff;
}
.btn.btn-purple {
  background-color: #9b6bcc !important;
  color: #fff;
  border: none;
}
.btn.btn-aqua {
  background-color: #27d7e7 !important;
  color: #fff;
  border: none !important;
}
.btn.btn-aqua:hover {
  background-color: #199ba7 !important;
  border-color: #199ba7 !important;
  color: #fff;
}
.btn.btn-brown {
  background-color: #9c8061 !important;
  color: #fff;
  border: none !important;
}
.btn.btn-brown {
  background-color: #9c8061 !important;
  color: #fff;
  border: none !important;
}
.btn.btn-brown:hover,
.btn.btn-brown:focus {
  background-color: #81674b !important;
  border-color: #81674b !important;
  color: #fff;
}
.btn.btn-light-green {
  background-color: #59b795 !important;
  border: none;
  color: #fff;
}
.btn.btn-light-green:hover,
.btn.btn-light-green:focus {
  background-color: #59b795 !important;
  border-color: #59b795 !important;
  color: #fff;
}
.btn.btn-light-green {
  background-color: #59b795 !important;
  border: none;
  color: #fff;
}
.btn.btn-light-green:hover,
.btn.btn-light-green:focus {
  background-color: #59b795 !important;
  border-color: #59b795 !important;
  color: #fff;
}
.btn.btn-outline-orange {
  color: #e67e22;
  border-color: #e67e22;
  background-color: transparent;
}
.btn.btn-outline-orange:hover {
  color: white;
  background-color: #e67e22 !important;
  border-color: #e67e22;
}
.btn.btn-outline-turquoise {
  color: #27ae60;
  border-color: #27ae60;
  background-color: transparent;
}
.btn.btn-outline-turquoise:hover {
  color: white;
  background-color: #27ae60 !important;
  border-color: #27ae60;
}
.btn.btn-outline-green {
  color: #2ecc71;
  border-color: #2ecc71;
  background-color: transparent;
}
.btn.btn-outline-green:hover {
  color: white;
  background-color: #2ecc71 !important;
  border-color: #2ecc71;
}
.btn.btn-outline-yellow {
  color: #f1c40f;
  border-color: #f1c40f;
  background-color: transparent;
}
.btn.btn-outline-yellow:hover {
  color: white;
  background-color: #f1c40f !important;
  border-color: #f1c40f;
}
.btn.btn-outline-purple {
  color: #334080;
  border-color: #334080;
  background-color: transparent;
}
.btn.btn-outline-purple:hover {
  color: white;
  background-color: #334080 !important;
  border-color: #334080;
}
.btn.btn-outline-aqua {
  color: #199ba7;
  border-color: #199ba7;
  background-color: transparent;
}
.btn.btn-outline-aqua:hover {
  color: white;
  background-color: #199ba7 !important;
  border-color: #199ba7;
}
.btn.btn-outline-brown {
  color: #303030;
  border-color: #303030;
  background-color: transparent;
}
.btn.btn-outline-brown:hover {
  color: white;
  background-color: #303030 !important;
  border-color: #303030;
}
.btn.btn-outline-light-green {
  color: #59b795;
  border-color: #59b795;
  background-color: transparent;
}
.btn.btn-outline-light-green:hover {
  color: white;
  background-color: #59b795 !important;
  border-color: #59b795;
}
.btn-arrow {
  display: inline-block;
  text-align: center;
  border-radius: 30px !important;
  width: 42px;
  height: 42px;
  line-height: 24px;
}

.btn-arrow i {
  font-size: 15px;
  display: inline-block;
  text-align: center;
}

.btn-sm.btn-arrow {
  width: 34px;
  height: 34px;
  line-height: 17px;
}

.btn-sm.btn-arrow i {
  font-size: 13px;
  line-height: 10px;
}

.btn {
  border-radius: 0px;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.75rem 1.3rem 0.6rem 1.3rem;
  transition: background-color box-shadow 0.1s linear;
}

.btn-shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
  transition: background-color box-shadow 0.1s linear;

  &:hover,
  &:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15),
      0 4px 6px 2px rgba(0, 0, 0, 0.15) !important;
  }
}

.btn-empty {
  background: transparent !important;
}

.btn.default {
  border-radius: $border-radius;
}

.btn-primary {
  background-color: $reactify-theme-color-1;
  border-color: $reactify-theme-color-1;
  color: $reactify-btn-text-color;

  &:hover {
    color: $reactify-btn-text-color;
    background-color: darken($reactify-theme-color-1, 8%);
    border-color: darken($reactify-theme-color-1, 8%);
  }
}

.check-button {
  cursor: default !important;
}

.check-button.btn-primary {
  background-color: $reactify-theme-color-1 !important;
  border-color: $reactify-theme-color-1 !important;
  opacity: 1;
}

.check-button .custom-control {
  min-height: 1.1rem;
  margin-top: -7px;
}

.dropdown-menu {
  font-size: 0.8rem;
  background: $reactify-input-background;
  width: 250px;
  border: 1px solid $reactify-separator-color !important;
  border-radius: 0 !important;
  top: -10px !important;
}
.dropdown-menu::before {
  content: "";
  position: absolute;
  right: 11px;
  width: 16px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid $reactify-separator-color;
  top: -11px;
  height: 10px;
}
.dropdown-menu::after {
  content: "";
  position: absolute;
  right: 12px;
  width: 14px;
  height: 12px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid $reactify-input-background;
  top: -12px;
}

.dropdown-item {
  padding: 0.75rem 1.5rem;
  color: $primary-color;
  cursor: pointer;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: $reactify-background-color;
  color: $primary-color;
}

.dropdown-item.active,
.dropdown-item:active {
  color: $reactify-btn-text-color;
  text-decoration: none;
  background-color: $reactify-theme-color-1;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: darken($reactify-theme-color-1, 12%);
  border-color: darken($reactify-theme-color-1, 12%);
  color: $reactify-btn-text-color;
}

.btn-secondary {
  background-color: $reactify-theme-color-2;
  border-color: $reactify-theme-color-2;
  color: $reactify-btn-text-color;

  &:hover {
    color: $reactify-btn-text-color;
    background-color: darken($reactify-theme-color-2, 8%);
    border-color: darken($reactify-theme-color-2, 8%);
  }
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: darken($reactify-theme-color-2, 12%);
  border-color: darken($reactify-theme-color-2, 12%);
  color: $reactify-btn-text-color;
}

.btn-primary.btn-primary-gradient {
  background: linear-gradient(
    to right,
    $reactify-theme-color-1,
    darken($reactify-theme-color-1, 15%)
  );

  &:hover {
    background: linear-gradient(
      to right,
      $reactify-theme-color-1,
      darken($reactify-theme-color-1, 8%)
    );
  }
}

.btn-primary-gradient:not(:disabled):not(.disabled):active,
.btn-primary-gradient:not(:disabled):not(.disabled).active,
.show > .btn-primary-gradient.dropdown-toggle {
  background: linear-gradient(
    to right,
    $reactify-theme-color-1,
    darken($reactify-theme-color-1, 15%)
  );
}

.btn-secondary-gradient {
  background: linear-gradient(
    to right,
    $reactify-theme-color-2,
    darken($reactify-theme-color-2, 15%)
  );

  &:hover {
    background: linear-gradient(
      to right,
      $reactify-theme-color-2,
      darken($reactify-theme-color-2, 8%)
    );
  }
}

.btn-secondary-gradient:not(:disabled):not(.disabled):active,
.btn-secondary-gradient:not(:disabled):not(.disabled).active,
.show > .btn-secondary-gradient.dropdown-toggle {
  background: linear-gradient(
    to right,
    $reactify-theme-color-2,
    darken($reactify-theme-color-2, 15%)
  );
}

.btn-warning {
  background-color: $warning-color;
  border-color: $warning-color;
}

.btn-success,
.btn-info,
.btn-danger,
.btn-warning {
  color: $reactify-btn-text-color;

  &:hover {
    color: $reactify-btn-text-color;
  }
}

.btn-outline-success,
.btn-outline-info,
.btn-outline-danger,
.btn-outline-warning {
  &:hover {
    color: $reactify-foreground-color;
  }
}

.btn-light {
  color: $reactify-dark-btn-bg;
  background-color: $reactify-light-btn-bg;
  border-color: $reactify-light-btn-bg;

  &:hover {
    color: $reactify-dark-btn-bg;
    background-color: darken($reactify-light-btn-bg, 8%);
    border-color: darken($reactify-light-btn-bg, 8%);
  }
}

.btn-dark {
  color: $reactify-light-btn-bg;
  background-color: $reactify-dark-btn-bg;
  border-color: $reactify-dark-btn-bg;

  &:hover {
    color: $reactify-light-btn-bg;
    background-color: darken($reactify-dark-btn-bg, 5%);
    border-color: darken($reactify-dark-btn-bg, 5%);
  }
}

.btn-outline-dark {
  color: $reactify-dark-btn-bg;
  border-color: $reactify-dark-btn-bg;

  &:hover {
    color: $reactify-foreground-color;
    background-color: $reactify-dark-btn-bg;
    border-color: $reactify-dark-btn-bg;
  }
}

.btn-outline-white {
  color: $reactify-btn-text-color;
  border-color: $reactify-btn-text-color;
  background-color: initial;

  &:hover {
    color: $reactify-theme-color-1;
    background-color: $reactify-btn-text-color;
  }
}

.btn-outline-light {
  color: $reactify-light-btn-bg;
  border-color: $reactify-light-btn-bg;

  &:hover {
    color: $reactify-foreground-color;
    background-color: $reactify-light-btn-bg;
    border-color: $reactify-light-btn-bg;
  }
}

.btn-outline-primary {
  color: $reactify-theme-color-1;
  border-color: $reactify-theme-color-1;

  &:hover {
    color: $reactify-foreground-color;
    background-color: $reactify-theme-color-1;
    border-color: $reactify-theme-color-1;
  }
}

.btn-outline-theme-3 {
  background: unset;
  color: $reactify-theme-color-3;
  border-color: $reactify-theme-color-3;

  &:hover {
    background-color: $reactify-theme-color-3;
    border-color: $reactify-theme-color-3;
    color: $reactify-foreground-color;
  }
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: $reactify-theme-color-1;
  border-color: $reactify-theme-color-1;
  color: $reactify-foreground-color;
}

.btn-outline-secondary {
  color: $reactify-theme-color-2;
  border-color: $reactify-theme-color-2;

  &:hover {
    background-color: $reactify-theme-color-2;
    border-color: $reactify-theme-color-2;
    color: $reactify-foreground-color;
  }
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  background-color: $reactify-theme-color-2;
  border-color: $reactify-theme-color-2;
  color: $reactify-foreground-color;
}

.btn-header-light {
  color: $reactify-separator-color;
  border-color: transparent;
  background: transparent;

  &:hover {
    background-color: transparent;
    border-color: $reactify-separator-color;
  }
}

.btn-header-primary {
  color: $reactify-theme-color-1;
  border-color: transparent;
  background: transparent;

  &:hover {
    background-color: transparent;
    border-color: $reactify-theme-color-1;
  }
}

.btn-header-secondary {
  color: $reactify-theme-color-2;
  border-color: transparent;
  background: transparent;

  &:hover {
    background-color: transparent;
    border-color: $reactify-theme-color-2;
  }
}

.btn-header-primary-light {
  color: $reactify-theme-color-3;
  border-color: transparent;
  background: transparent;

  &:hover {
    background-color: transparent;
    border-color: $reactify-theme-color-3;
  }
}

.btn-xl,
.btn-group-xl > .btn {
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding: 1rem 3.5rem 0.9rem;
}

.btn-lg,
.btn-group-lg > .btn {
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding: 0.75rem 2.6rem 0.6rem 2.6rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.5rem 1rem;
  font-size: 0.76rem;
  line-height: 1.5;
}

.btn-xs,
.btn-group-xs > .btn {
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
  font-size: 0.76rem;
  line-height: 1.3;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: $reactify-theme-color-1;
  border-color: $reactify-theme-color-1;
  color: $reactify-foreground-color;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: $reactify-theme-color-2;
  border-color: $reactify-theme-color-2;
  color: $reactify-foreground-color;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.btn-link {
  color: $reactify-theme-color-1;
}

.btn-link:hover {
  color: rgba($reactify-theme-color-1, 0.8);
  text-decoration: underline;
}

.white-underline-link {
  color: $reactify-btn-text-color;
  text-decoration: underline;

  &:hover,
  &:active {
    color: $reactify-btn-text-color;
    text-decoration: initial;
  }
}

.btn-multiple-state {
  position: relative;
  transition: opacity 500ms;

  .spinner,
  .icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 500ms;
    color: $reactify-btn-text-color;
  }

  .icon i {
    vertical-align: text-bottom;
    font-size: 18px;
  }

  .label {
    transition: opacity 500ms;
  }

  &.show-spinner {
    .label {
      opacity: 0;
    }

    .spinner {
      opacity: 1;
      visibility: visible;
    }
  }

  &.show-success {
    .label {
      opacity: 0;
    }

    .icon.success {
      opacity: 1;
      visibility: visible;
    }
  }

  &.show-fail {
    .label {
      opacity: 0;
    }

    .icon.fail {
      opacity: 1;
      visibility: visible;
    }
  }

  &.btn-primary:disabled {
    opacity: 1;
    background: darken($reactify-theme-color-1, 10%);
    border-color: darken($reactify-theme-color-1, 10%);
  }

  &.btn-secondary:disabled {
    opacity: 1;
    border-color: darken($reactify-theme-color-2, 10%);
  }
}

.icon-button {
  padding: 0;
  font-size: 14px;
  width: 34px;
  height: 34px;
  line-height: 34px;

  &.large {
    width: 44px;
    height: 44px;
    font-size: 18px;
  }

  &.small-icon {
    font-size: 12px;
    line-height: 32px;
  }
}

.top-right-button {
  width: calc(100% - 80px);

  @include respond-below(xs) {
    padding-left: 0;
    padding-right: 0;
  }
}

.top-right-button-single {
  width: unset;

  @include respond-below(md) {
    width: calc(100%);
  }
}

/* 03.Widths-Spacing */
.w-10 {
  width: 10% !important;
}

.w-90 {
  width: 90% !important;
}

.w-12 {
  width: 12% !important;
}

.w-88 {
  width: 88% !important;
}

.w-15 {
  width: 15% !important;
}

.w-85 {
  width: 85% !important;
}

.w-20 {
  width: 20% !important;
}
.w-15px {
  width: 15px;
}
.w-20px {
  width: 20px;
}
.dropdown-2 {
  width: 200px !important;
  left: -7px !important;
}
.w-80 {
  width: 80% !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-xs-100 {
  @include respond-below(sm) {
    width: 100% !important;
  }
}

.w-sm-100 {
  @include respond-below(md) {
    width: 100% !important;
  }
}

.r-0 {
  right: 0;
}

.l-0 {
  left: 0;
}

.depth-1 {
  @include depth(1);
}

.depth-2 {
  @include depth(2);
}

.min-width-zero {
  min-width: 0;
}

.mb-5,
.my-5 {
  margin-bottom: 2rem !important;
}

.float-none-xs {
  @include respond-below(xs) {
    float: initial !important;
  }
}

/* 04.Borders */
.border {
  border: 1px solid $reactify-separator-color-light !important;
}

.border-right {
  border-right: 1px solid $reactify-separator-color-light !important;
}

.border-left {
  border-left: 1px solid $reactify-separator-color-light !important;
}

.border-top {
  border-top: 1px solid $reactify-separator-color-light !important;
}

.border-bottom {
  border-bottom: 1px solid $reactify-separator-color-light !important;
}

.border-square {
  border: 1px solid $reactify-separator-color-light !important;
}

.border-primary,
.border-theme-1 {
  border-color: $reactify-theme-color-1 !important;
}

.border-theme-2 {
  border-color: $reactify-theme-color-2 !important;
}

.border-theme-3 {
  border-color: $reactify-theme-color-3 !important;
}

.border-secondary {
  border-color: $primary-color !important;
}

// Popular Items

.img120px {
  width: 120px;
}
/* 05.Badges */
.badge.badge-reactify {
  background: #72c02c !important;
  color: #fff !important;
}
.badge.badge-aqua {
  background: #27d7e7 !important;
  color: #fff;
}
.badge.badge-brown {
  background: #9c8061 !important;
  color: #fff;
}
.badge.badge-red {
  background: #e74c3c !important;
  color: #fff;
}
.badge.badge-dark-blue {
  background: #4765a0 !important;
  color: #fff;
}
.badge.badge-light-green {
  background: #79d5b3 !important;
  color: #fff;
}
.badge.badge-sea {
  background: #1abc9c !important;
  color: #fff;
}
.badge.badge-orange {
  background: #e67e22 !important;
  color: #fff;
}
.badge.badge-yellow {
  background: #f1c40f !important;
  color: #fff;
}
.bg-theme-2,
.badge-theme-2 {
  background-color: $reactify-theme-color-2 !important;
  color: $reactify-btn-text-color;
}
.bold-fonts {
  font-weight: bold;
}

.bg-theme-3,
.badge-theme-3 {
  background-color: $reactify-theme-color-3 !important;
  color: $reactify-btn-text-color;
}

.bg-primary,
.bg-theme-1,
.badge-primary,
.badge-theme-1 {
  background-color: $reactify-theme-color-1 !important;
  color: $reactify-btn-text-color;
  border: none;
}
.bg-main-color {
  background: $reactify-background-color !important;
  color: $reactify-theme-color-1 !important;
  border: none;
}

.bg-secondary,
.badge-secondary {
  background-color: $reactify-theme-color-2 !important;
  color: $reactify-btn-text-color;
}

.badge-warning {
  background-color: $warning-color;
}

.badge-success {
  background-color: $success-color;
}

.badge-info {
  background-color: $info-color;
}

.badge-danger {
  background-color: $error-color;
}

.badge-success,
.badge-danger,
.badge-warning,
.badge-info {
  color: $reactify-btn-text-color;
}

.badge {
  padding: 0.55em 0.75em 0.6em 0.75em;
  font-size: 74%;

  &.badge-pill {
    padding-right: 1.25em;
    padding-left: 1.25em;
  }

  &.badge-top-left {
    top: 10px;
    left: -7px;
  }

  &.badge-top-left-2 {
    top: 40px;
    left: -7px;
  }

  &.badge-top-right {
    top: 8px;
    right: -7px;
  }

  &.badge-top-right-2 {
    top: 40px;
    right: -7px;
  }
}

.badge-light {
  background-color: $reactify-light-btn-bg;
  color: $reactify-dark-btn-bg;
}

.badge-dark {
  background-color: $reactify-dark-btn-bg;
  color: $reactify-light-btn-bg;
}

.badge-outline-primary,
.badge-outline-theme-1 {
  background: unset;
  border: 1px solid $reactify-theme-color-1;
  color: $reactify-theme-color-1;
}

.badge-outline-secondary,
.badge-outline-theme-2 {
  background: unset;
  border: 1px solid $reactify-theme-color-2;
  color: $reactify-theme-color-2;
}

.badge-outline-theme-3 {
  background: unset;
  border: 1px solid $reactify-theme-color-3;
  color: $reactify-theme-color-3;
}

.badge-outline-success {
  background: unset;
  border: 1px solid $success-color;
  color: $success-color;
}

.badge-outline-danger {
  background: unset;
  border: 1px solid $error-color;
  color: $error-color;
}

.badge-outline-warning {
  background: unset;
  border: 1px solid $warning-color;
  color: $warning-color;
}

.badge-outline-info {
  background: unset;
  border: 1px solid $info-color;
  color: $info-color;
}

.badge-outline-light {
  background: unset;
  border: 1px solid $reactify-light-btn-bg;
  color: $reactify-light-btn-bg;
}

.badge-outline-dark {
  background: unset;
  border: 1px solid $reactify-dark-btn-bg;
  color: $reactify-dark-btn-bg;
}

/* 06.Breadcrumb */
.breadcrumb-container {
  .breadcrumb {
    @include respond-below(md) {
      padding: 0;
    }
  }
}

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "|";
}

/* 07.Tabs */
.nav-tabs.separator-tabs {
  border-bottom: 1px solid $reactify-separator-color;
}
.display-block {
  display: block !important;
}
.sub-menu-links {
  font-weight: bold;
}
.sub-menu-collapse {
  border-left: 2px solid #e9ecef;
  padding: 10px;
  margin-top: 5px;
  margin-left: 6px;
}
.nav-tabs .nav-link {
  border: initial;
  padding-top: 1rem;
}
.nav-item {
  list-style: none;
}
.active-menu {
  font-size: 18px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background: $reactify-foreground-color;
}

.nav-tabs .nav-link.active::before,
.nav-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: $reactify-theme-color-1;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 5px;
  left: 0;
  top: 0;
}

.nav-tabs.separator-tabs .nav-link.active::before,
.nav-tabs.separator-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: $reactify-theme-color-1;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  top: unset;
}

.nav-tabs.separator-tabs .nav-link {
  border: initial;
  padding-top: 1rem;
  background: initial;
  padding-left: 0;
  padding-top: 0.5rem;
  padding-right: 0;
  margin-right: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: $secondary-color;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: initial;
  position: relative;
  color: $reactify-theme-color-1;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: initial;
  color: $reactify-theme-color-1;
}

// 08.React Siema Carousel
.react-siema-container {
  overflow: hidden;
  padding-bottom: 15px;
  padding-top: 5px;
}
.user-profile-images-c {
  border: 10px solid #fff;
  border-radius: 50% !important;
  margin-bottom: -25px;
}

.slider-nav {
  .left-arrow,
  .right-arrow {
    font-size: 20px;
    color: $reactify-theme-color-1;
    display: inline-block;
    vertical-align: middle;
    margin: 0 15px;
    padding-top: 14px;
  }

  .slider-dot-container {
    display: inline-block;
  }

  .btn {
    &:hover,
    &:focus,
    &:active {
      text-decoration: initial;
    }
  }
}

.slider-dot {
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background: $reactify-separator-color;
  outline: initial !important;
  border: initial;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;

  &.active {
    background: $reactify-theme-color-1;
  }
}

.react-siema-container .card .card-body {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.react-siema-container .card .w-50 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
// todo buttons

.todobtn {
  height: 32px;
  cursor: pointer;
  font-size: 12px;
  padding: 6px;
}

/* Dashboar one */
.bold {
  font-weight: bold;
}
.border-1px {
  border: 1px solid #e9ecef;
}
.font18px {
  font-size: 18px;
}
.font15px {
  font-size: 15px;
}
.font12px {
  font-size: 12px;
}
.cursorp {
  cursor: pointer !important;
}
.margin-bottom-159 {
  margin-bottom: -159px !important;
}
/* 09.Navbar*/
.offcanvas-main-links {
  border-bottom: 1px dotted #e9ecef;
  height: 40px;
  align-items: center;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  color: #212229;
  padding-bottom: 2px;
}
.sub-menu-cus {
  border-bottom: 1px dotted #e9ecef;
  line-height: 40px;
}
.color-light {
  color: #ced4da;
}
.navbar {
  background: $reactify-foreground-color;
  height: $navbar-height;
  padding: 0px 20px 0px 0 !important;
  @include depth(1);

  #notificationDropdown {
    width: 335px;
    padding: 1.5rem;
    height: 280px;
    right: 15px;
  }
  .align-notify-icon {
    margin-left: 14px;
    margin-top: 14px;
  }
  .align-notify-icon-2 {
    margin-left: 12px;
    margin-top: 14px;
  }
  .notify-icon-bg-size {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  #notificationDropdownMessage {
    width: 310px;
    padding: 1.5rem;
    height: auto;
    right: 15px;
  }
  #iconMenuDropdown {
    width: 240px;
    padding: 1.5rem;
    height: 280px;
  }
  .online-status {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    overflow: hidden;
    background: $success-color;
    left: 17px;
    border: 4px solid $reactify-separator-color-light;
  }
  .offline-status {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    overflow: hidden;
    background: $error-color;
    border: 4px solid $reactify-separator-color-light;
    left: 17px;
  }

  .icon-menu-item {
    width: 90px;
    display: inline-block;
    text-align: center;
    margin-bottom: 1.7rem;
    color: rgba($primary-color, 0.8);

    i {
      font-size: 28px;
      line-height: 42px;
    }

    span {
      text-align: center;
      padding: 0 10px;
      line-height: 14px;
    }

    &:hover,
    &:focus {
      color: $reactify-theme-color-1;
    }
  }

  .menu-button-mobile {
    color: $secondary-color;
    text-align: center;

    svg {
      height: 12px;
      fill: $primary-color;
    }

    @include respond-below(sm) {
      width: 20px;
    }
  }

  .menu-button {
    color: $secondary-color;
    width: $main-menu-width;
    text-align: center;

    svg {
      height: 12px;
    }

    .main {
      fill: $primary-color;
      transition: fill $menu-collapse-time;
    }

    .sub {
      fill: $primary-color;
      transition: fill $menu-collapse-time;
    }

    &:hover {
      color: $reactify-theme-color-1;
    }

    @include respond-below(sm) {
      width: 60px;
    }
  }

  .navbar-logo {
    width: 110px;
    height: 35px;

    .logo {
      width: 100%;
      height: 100%;
      background: url($getLogo) no-repeat;
      background-position: center center;
      background-size: cover;
    }
    .logo-width {
      width: 150px;
    }

    .logo-mobile {
      width: 100%;
      height: 100%;
      background: url($getLogoMobile) no-repeat;
      background-position: center center;
      background-size: cover;
    }

    @include respond-below(sm) {
      width: 80px;
    }

    @include respond-below(xxs) {
      width: 20px;
    }
  }

  .search {
    position: relative;
    width: $sub-menu-width;
    background: $reactify-background-color;

    input {
      border: initial;
      background: transparent;
      outline: initial !important;
      padding: 0.5rem 1rem;
      line-height: 2;
      font-size: 0.8rem;
      width: 93%;
      color: $primary-color;
    }

    .search-icon {
      font-size: 17px;
      border-radius: 10px;
      color: $secondary-color;
      position: absolute;
      width: 40px;
      height: 40px;
      bottom: -8px;
      right: 3px;
      text-align: center;
      cursor: pointer;

      &:hover {
        color: $reactify-theme-color-1;
      }
    }

    @include respond-below(md) {
      width: $sub-menu-width/2;

      input {
        width: 85%;
      }
    }

    @include respond-below(sm) {
      width: 30px;
      height: 30px;
      background: initial;
      margin-left: 0.6rem;
      color: rgba($primary-color, 0.7);

      input {
        display: none;
      }

      .search-icon {
        font-size: 17px;
        width: 30px;
        height: 30px;
        bottom: -3px;
        right: 0;
        color: inherit;
      }

      &.mobile-view {
        display: block;
        width: 100%;
        position: fixed;
        z-index: 2;
        background: $reactify-foreground-color;
        left: 0;
        top: 0;
        height: $navbar-height-xs;
        margin-left: 15px;

        input {
          display: block;
          width: 100%;
          height: 70px;
          padding-left: 0;
        }

        span {
          top: 50%;
          transform: translateY(-50%);
          right: 25px;
        }
      }
    }
  }

  .header-icons {
    margin-right: 1rem;

    @include respond-below(xs) {
      margin-right: 0;
    }
  }

  .header-icon {
    font-size: 16px;
    color: $secondary-color;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    vertical-align: initial;

    @include respond-below(xs) {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }

    &:hover {
      color: $reactify-theme-color-1;
    }

    &#fullScreenButton i:last-of-type {
      display: none;
    }

    &.notificationButton {
      .count {
        font-size: 9px;
        color: $reactify-theme-color-1;
        border-radius: 10px;
        position: absolute;
        width: 18px;
        height: 15px;
        text-align: center;
        font-weight: 700;
        top: 2px;
        right: 2px;
        line-height: 14px;

        @include respond-below(xs) {
          right: -1px;
        }
      }
    }
  }

  .user {
    color: lighten($primary-color, 30%);
    position: relative;

    img {
      margin-left: 10px;
      border-radius: 30px;
      width: 40px;
    }

    @include respond-below(md) {
      .name {
        display: none;
      }
    }

    @include respond-below(sm) {
      img {
        width: 30px;
      }

      margin-left: initial;

      &:after {
        font-size: 11px;
        width: 14px;
        height: 14px;
        bottom: -3px;
        right: -3px;
      }
    }
  }

  @include respond-below(xl) {
    height: $navbar-height-lg;
  }

  @include respond-below(lg) {
    height: $navbar-height-md;
    padding: 1.5rem $main-margin-md 1.5rem 0;
  }

  @include respond-below(sm) {
    height: $navbar-height-xs;
    padding: $main-margin-xs;
  }
}

#app-container {
  &.sub-hidden .menu-button,
  &.menu-sub-hidden .menu-button {
    .sub {
      fill: $secondary-color;
    }
  }

  &.main-hidden .menu-button,
  &.menu-hidden .menu-button {
    .main,
    .sub {
      fill: $secondary-color;
    }
  }
}

/* 10.Menu*/
.position-bottom {
  position: absolute;
  bottom: 0px;
}
#app-container .sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  padding-top: $navbar-height;
  z-index: 4;
  height: calc(100% - #{$navbar-height});

  .scrollbar-container {
    margin-right: 0;
    padding-right: 0;
  }

  .main-menu {
    width: $main-menu-width;
    height: calc(100% - #{$navbar-height});
    background: $reactify-foreground-color;
    z-index: 3;
    position: fixed;
    transition: transform $menu-collapse-time;
    padding-top: 2px;
    left: 0;

    .scroll {
      padding-right: unset;
      margin-right: unset;
      height: 100%;

      .ps__thumb-y {
        right: 0;
      }
    }

    &.main-hidden {
      transform: translateX(-$main-menu-width);
    }

    ul li {
      position: relative;

      span {
        text-align: center;
        padding: 0 10px;
        line-height: 14px;
      }

      a {
        height: $main-menu-item-height;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        border-bottom: 1px solid $reactify-separator-color-light;
        color: $primary-color;
        transition: color $menu-collapse-time;
        transition: background $menu-collapse-time;

        &:hover,
        &:focus {
          color: $reactify-theme-color-1;
          background: $reactify-background-color;
        }
      }

      i {
        font-size: 32px;
        line-height: 42px;
      }

      &.active a {
        color: $reactify-theme-color-1;
      }

      &.active:after {
        content: " ";
        background: $reactify-theme-color-1;
        border-radius: 10px;
        position: absolute;
        width: 6px;
        height: 90px;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }

    @include respond-below(xl) {
      width: $main-menu-width-lg;
      height: calc(100% - #{$navbar-height-lg});
    }

    @include respond-below(lg) {
      width: $main-menu-width-md;
      height: calc(100% - #{$navbar-height-md});
    }

    @include respond-below(sm) {
      width: $main-menu-width-xs;
      height: calc(100% - #{$navbar-height-xs});

      ul li i {
        font-size: 28px;
        line-height: 38px;
      }

      ul li a {
        height: $main-menu-item-height-mobile;
      }

      ul li.active:after {
        width: 3px;
        height: 60px;
      }
    }
  }

  .sub-menu {
    width: $sub-menu-width;
    background: $reactify-foreground-color;
    z-index: 2;
    position: fixed;
    left: $main-menu-width;
    border-left: 1px solid $reactify-separator-color-light;
    @include depth(2);
    transition: transform $menu-collapse-time;
    height: calc(100% - #{$navbar-height});

    .scroll {
      height: 100%;
      margin-top: 2px;
      margin-right: unset;
      padding-right: unset;

      .ps__thumb-y {
        right: 0;
      }
    }

    .ps {
      padding-top: 25px;
      padding-bottom: 25px;
    }

    ul {
      display: none;

      li {
        margin-bottom: 10px;
        margin-left: 30px;

        a {
          font-size: 13px;
          display: block;
          padding: 8px 0;
        }

        i {
          font-size: 1.3em;
          margin-right: 10px;
          color: $secondary-color;
          vertical-align: middle;
        }

        span {
          vertical-align: middle;
          padding-top: 3px;
          display: inline-block;
        }

        &.active i,
        &.active a {
          color: $reactify-theme-color-1;
        }

        @include respond-below(sm) {
          margin-left: 15px;
        }
      }
    }

    @include respond-below(xl) {
      left: $main-menu-width-lg;
      width: $sub-menu-width-lg;
      height: calc(100% - #{$navbar-height-lg});
    }

    @include respond-below(lg) {
      left: $main-menu-width-md;
      width: $sub-menu-width-md;
      height: calc(100% - #{$navbar-height-md});
    }

    @include respond-below(sm) {
      left: $main-menu-width-xs;
      width: $sub-menu-width-xs;
      height: calc(100% - #{$navbar-height-xs});
    }
  }

  @include respond-below(xl) {
    padding-top: $navbar-height-lg;
  }

  @include respond-below(lg) {
    padding-top: $navbar-height-md;
  }

  @include respond-below(sm) {
    padding-top: $navbar-height-xs;
    @include depth(1);
  }
}

//Menu Config Main Menu Sub Menu
#app-container {
  &.sub-hidden .sub-menu,
  &.menu-sub-hidden .sub-menu,
  &.menu-hidden .sub-menu {
    transform: translateX(-$sub-menu-width);

    @include respond-below(sm) {
      transform: translateX(-$sub-menu-width-xs);
    }
  }

  &.main-hidden .main-menu,
  &.menu-hidden .main-menu {
    transform: translateX(-$main-menu-width);

    @include respond-below(xl) {
      transform: translateX(-$main-menu-width-lg);
    }

    @include respond-below(lg) {
      transform: translateX(-$main-menu-width-md);
    }

    @include respond-below(sm) {
      transform: translateX(-$main-menu-width-xs);
    }
  }

  &.main-hidden.sub-hidden .sub-menu,
  &.menu-hidden .sub-menu {
    transform: translateX(-$sub-menu-width - $main-menu-width);

    @include respond-below(sm) {
      transform: translateX(-$sub-menu-width-xs - $main-menu-width-xs);
    }
  }

  //Mobile
  &.menu-mobile {
    .main-menu {
      transform: translateX(-$main-menu-width-xs);
    }

    .sub-menu {
      transform: translateX(-$sub-menu-width-xs - $main-menu-width-xs - 50);
    }
  }

  //Show Temporary
  &.main-show-temporary {
    .main-menu {
      transform: translateX(0);
    }

    .sub-menu {
      transform: translateX(-$sub-menu-width);
    }

    @include respond-below(xl) {
      .main-menu {
        transform: translateX(0);
      }

      .sub-menu {
        transform: translateX(-$sub-menu-width-lg);
      }
    }

    @include respond-below(lg) {
      .main-menu {
        transform: translateX(0);
      }
    }

    @include respond-below(sm) {
      .sub-menu {
        transform: translateX(-$sub-menu-width-xs);
      }
    }
  }
}
.bg-background-color {
  background: $reactify-background-color !important;
}
.boxshadow-0 {
  box-shadow: none !important;
}
.lineheight-0 {
  line-height: 0px !important;
}
.bg-ticket {
  background: $reactify-separator-color-light;
}

.font40px {
  font-size: 40px;
}

.h-250 {
  height: 250px !important;
}

/* 12.Main*/
main {
  margin-left: $sub-menu-width + $main-menu-width + $main-margin;
  margin-top: $navbar-height + $main-margin - 15;
  margin-right: $main-margin;
  margin-bottom: $main-margin - 20;
  transition: margin-left $menu-collapse-time;

  &.sub-hidden {
    margin-left: $main-menu-width + $main-margin;
  }

  &.main-hidden {
    margin-left: $main-margin;
  }

  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  @include respond-below(xl) {
    margin-left: $sub-menu-width-lg + $main-menu-width-lg + $main-margin-lg;
    margin-right: $main-margin-lg;
    margin-top: $main-margin-lg + $navbar-height-lg -10;
    margin-bottom: 30px;
  }

  @include respond-below(lg) {
    margin-left: $sub-menu-width-md + $main-menu-width-md + $main-margin-md;
    margin-right: $main-margin-md;
    margin-top: $main-margin-md + $navbar-height-md -10;
    margin-bottom: 20px;
  }

  @include respond-below(sm) {
    margin-left: $main-margin-xs !important;
    margin-right: $main-margin-xs !important;
    margin-top: $main-margin-xs + $navbar-height-xs !important;
    margin-bottom: 0;
  }

  @include respond-below(xs) {
    margin-bottom: 0;
  }
}

//Menu Config Main
#app-container {
  &.sub-hidden main,
  &.menu-sub-hidden main,
  &.menu-hidden main {
    margin-left: $main-menu-width + $main-margin;
  }

  &.main-hidden main,
  &.menu-hidden main {
    margin-left: $main-margin;
  }

  @include respond-below(xl) {
    &.sub-hidden main,
    &.menu-sub-hidden main,
    &.menu-hidden main {
      margin-left: $main-menu-width-lg + $main-margin-lg;
    }

    &.main-hidden main,
    &.menu-hidden main {
      margin-left: $main-margin-lg;
    }
  }

  @include respond-below(lg) {
    &.sub-hidden main,
    &.menu-sub-hidden main,
    &.menu-hidden main {
      margin-left: $main-menu-width-md + $main-margin-md;
    }

    &.main-hidden main,
    &.menu-hidden main {
      margin-left: $main-margin-md;
    }
  }
}

/* 13.Cards*/
.card {
  border: initial;
  background: $reactify-foreground-color;

  .card-header .card-icon {
    right: 5px;
    top: 5px;

    i {
      font-size: 12px;
      color: $reactify-separator-color;
    }
  }

  .card-subtitle {
    margin: 0;
    margin-bottom: 1rem;
  }

  .card-header .handle {
    cursor: default;
  }

  border-radius: calc(0.15rem - 1px);
  @include depth(1);

  .card-body {
    padding: 1.75rem;

    @include respond-below(xs) {
      padding: 1.25rem;
    }

    &.sm {
      padding: 1.25rem 1.75rem;
    }
  }

  .card-title {
    margin-bottom: 2rem;

    @include respond-below(sm) {
      margin-bottom: 1.25rem;
    }
  }
}

.card-img {
  border-radius: calc(0.25rem - 1px);
  height: 100%;
  object-fit: cover;
  max-height: 200px;
  width: unset;
}

.card-img-fluid {
  border-radius: calc(0.25rem - 1px);
  object-fit: cover;
}

.card-img-bottom {
  width: 100%;
  border-bottom-left-radius: calc(0.15rem - 1px);
  border-bottom-right-radius: calc(0.15rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.15rem - 1px);
  border-top-right-radius: calc(0.15rem - 1px);
}

.card-img-left {
  width: 100%;
  border-top-left-radius: calc(0.15rem - 1px);
  border-bottom-left-radius: calc(0.15rem - 1px);
  height: 100%;
  object-fit: cover;
}

.card-img-right {
  width: 100%;
  border-top-right-radius: calc(0.15rem - 1px);
  border-bottom-right-radius: calc(0.15rem - 1px);
  height: 100%;
  object-fit: cover;
}

.card-img-overlay {
  background: rgba(#000000, 0.5);
  padding: 1.75rem;

  @include respond-below(xs) {
    padding: 1rem;
  }
}

.card-top-buttons {
  padding: 1.3rem;
  right: 0;
  top: 0;

  @include respond-below(xs) {
    padding: 0.35rem;
  }
}

.card-header {
  border: initial;
  background: initial;
  padding-top: 0;
}

/* 14.Dashboard*/

.dashboard-line-chart {
  height: 283px;
}
.chart-height-limit {
  max-height: 17px !important;
}

.dashboard-quick-post {
  min-height: 263px;
}

.dashboard-list-with-thumbs {
  height: 500px;
}

.dashboard-tasks {
  height: 270px;
}
.w-200 {
  width: 200px;
}
.w-350 {
  width: 350px;
}
@media only screen and (max-width: 480px) {
  .w-350 {
    width: 200px;
  }
}
.dashboard-list-with-user {
  height: 270px;
}
.dashboard-recent-act-list {
  height: 270px;
}
.dashboard-products-list {
  height: 270px;
}

.dashboard-donut-chart {
  height: 270px;
}
.dashboard-bar-chart {
  height: 170px;
}
.fontsize10px {
  font-size: 10px;
}
.dashboard-small-chart {
  height: 150px;

  .chart {
    height: 75px;
  }

  .lead {
    font-size: 1.4rem;
  }
}

.dashboard-small-chart-analytics {
  height: 180px;

  .chart {
    height: 85px;
  }

  .lead {
    font-size: 1.4rem;
  }
}

.dashboard-filled-line-chart {
  height: 340px;

  .chart {
    height: 200px;
  }
}

.dashboard-sq-banner {
  // background: url(/img/sq-banner.jpg);
  // background-image: linear-gradient(to right bottom, $reactify-theme-color-1, $reactify-theme-color-2);
  background-image: linear-gradient(
    to right top,
    $reactify-gradient-color-2,
    $reactify-gradient-color-3,
    $reactify-gradient-color-1
  );
  background-size: cover;
  height: 385px;
  transition: 0.5s;
  background-size: 350% auto;
  cursor: pointer;

  .card-body {
    width: 270px;
  }

  .lead {
    line-height: 2.3rem;
  }

  &:hover {
    background-position: right top;
  }
}

.dashboard-link-list {
  height: 385px;
}

.dashboard-progress {
  height: 385px;
}

.dashboard-top-rated {
  height: 300px;

  @include respond-below(md) {
    height: unset;
  }

  .react-siema-container {
    margin-right: -0.5rem;
    margin-left: -0.5rem;

    img {
      height: 120px;
      display: flex;
      object-fit: cover;
      width: 100%;
    }

    .react-rater {
      display: inline-block !important;
    }
  }
}

.dashboard-search {
  height: 650px;
  background-color: $reactify-foreground-color;

  .card-body {
    padding: 120px 50px 30px 50px;

    @include respond-below(xl) {
      padding: 80px 30px 30px 30px;
    }
  }

  .form-container {
    height: 400px;
    border-radius: $border-radius;
    box-shadow: 0px -10px 15px 0px rgba(0, 0, 0, 0.04);
    padding: 2rem;
    background-color: $reactify-foreground-color;
  }
}

.icon-cards-row {
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px;

  .react-siema-container {
    padding-bottom: 0px;
    padding-top: 10px;

    .icon-row-item {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
  }

  .card-body {
    padding: 2rem 0.5rem;
  }

  .card-text {
    color: $secondary-color;
    height: 30px;
    line-height: 26px;
  }

  .lead {
    color: $reactify-theme-color-1;
    margin-bottom: 0;
  }
  i {
    font-size: 46px;
    line-height: 66px;
    color: $reactify-theme-color-1;
  }

  .card {
    transition: box-shadow $animation-time-long;
    cursor: pointer;
  }

  @include respond-below(xl) {
    .card-text {
      height: 48px;
      line-height: 18px;
    }

    .lead {
      font-size: 1.6rem;
    }

    i {
      font-size: 32px;
      line-height: 47px;
    }
  }
}

.log-indicator {
  width: 13px;
  height: 13px;
  border: 2px solid $reactify-theme-color-1;
  border-radius: 14px;
  display: inline-block;
}

/* 15.Calendar */
.big-calendar-header {
  margin-bottom: 1em;
}

.rbc-month-header {
  min-height: 50px;
}

.rbc-today {
  background: initial;
}

.calendar-prev-btn,
.calendar-next-btn {
  outline: initial !important;
  box-shadow: initial !important;
  border-radius: 40px !important;
  text-align: center;
  min-width: 30px;
  height: 30px;
  padding: 0.55rem 0;
  background: $reactify-theme-color-1;
  color: $reactify-foreground-color;
  border: 1px solid $reactify-theme-color-1;
  line-height: 0.9 !important;
  font-size: 0.76rem;
  font-weight: normal !important;

  span {
    line-height: 1 !important;
    font-size: 0.76rem;
    font-weight: normal !important;
  }

  &:hover {
    background-color: transparent;
    border-color: lighten($reactify-theme-color-1, 10%);
    color: $reactify-theme-color-1;
  }
}

.calendar-prev-btn {
  margin-right: 5px;
}

.calendar-today-btn {
  padding: 0.4em 1.3em !important;
  height: unset !important;
}

.rbc-month-row {
  min-height: 5em;
  z-index: 1;

  @include respond-below(xs) {
    min-height: 3em;
  }
}

.rbc-month-view {
  border: initial;
}

.rbc-off-range-bg {
  background: initial;
}

.rbc-off-range {
  color: $primary-color;
  opacity: 0.3;
}

.rbc-day-bg + .rbc-day-bg,
.rbc-month-row + .rbc-month-row,
.rbc-header + .rbc-header,
.rbc-header {
  border-color: $reactify-separator-color-light !important;
}

.rbc-header {
  padding: 15px 5px;
  color: $reactify-theme-color-1;
}

.rbc-date-cell {
  padding: 10px;

  a {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    text-align: center;
    vertical-align: middle;
    padding: 5px;
    font-weight: initial;
    display: inline-block;
  }
}

.rbc-date-cell.rbc-now a {
  background: $reactify-theme-color-1;
  color: $reactify-foreground-color;
}

.rbc-event {
  font-size: 0.85em;
  border-radius: 25px;
  text-align: center;
  padding: 0px 5px;
  background: $reactify-theme-color-2;
}

.fc-basic-view .fc-body .fc-row {
  min-height: 6em;

  @include respond-below(xs) {
    min-height: 3em;
  }
}

.fc-bootstrap4 .fc-day-top .fc-day-number {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  text-align: center;
  vertical-align: middle;
  padding: 5px;
}

.fc-bootstrap4 td.fc-today {
  background: initial;

  .fc-day-number {
    background: $reactify-theme-color-1;
    color: $reactify-btn-text-color;
  }
}

.fc-day-grid-container {
  height: 100%;
  overflow: visible;
}

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  padding: 5px;
}

.fc-view,
.fc-view > table {
  border-top: initial;
}

.fc table {
  border: initial;
}

.fc .table-bordered thead th,
.fc .table-bordered thead td {
  border-top: initial;
  border-bottom: initial;
  border-left: initial;
}

.fc td:first-of-type,
.fc th:first-of-type {
  border-left: initial;
  border-bottom: initial;
}

.fc td:last-of-type,
.fc th:last-of-type {
  border-right: initial;
  border-bottom: initial;
}

.fc-body .fc-row:last-of-type td {
  border-bottom: initial;
}

.fc tbody > tr > td.fc-widget-content:first-of-type {
  border-left: initial;
  border-right: initial;
  border-bottom: initial;
}

.fc table,
.fc .table-bordered th,
.fc .table-bordered td {
  border-color: $reactify-separator-color-light !important;
}

.fc-day-header {
  color: $reactify-theme-color-1;

  span {
    padding: 10px;
    display: inline-block;
  }
}

.fc-event {
  border: initial;
}

.fc-event,
.fc-event-dot {
  background-color: $reactify-theme-color-2;
  color: $reactify-btn-text-color !important;
  padding: 1px 6px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
}

/* 16.Datatable */
.ReactTable {
  border: initial;
}
.w-100px {
  min-width: 100px;
}
.w50px {
  width: 50px;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  padding-top: 20px;
  padding-bottom: 10px;
}

.ReactTable .rt-thead.-header {
  box-shadow: initial;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: initial;
  border: initial;
  text-align: left;
  font-weight: 700;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid $reactify-separator-color-light;
}

.ReactTable .list-item-heading {
  margin-bottom: 0;
}

.ReactTable .rt-tbody .rt-td {
  border-right: initial;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 $reactify-theme-color-1;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 $reactify-theme-color-1;
}

/* 17.Alerts */
.alert {
  border-radius: 0;
}

.alert-primary {
  color: $reactify-theme-color-1;
  background-color: rgba($reactify-theme-color-1, 0.2);
  border-color: rgba($reactify-theme-color-1, 0.1);
}

.alert-secondary {
  color: $reactify-theme-color-2;
  background-color: rgba($reactify-theme-color-2, 0.2);
  border-color: rgba($reactify-theme-color-2, 0.1);
}

.alert-success {
  color: $success-color;
  background-color: rgba($success-color, 0.2);
  border-color: rgba($success-color, 0.1);
}

.alert-info {
  color: $info-color;
  background-color: rgba($info-color, 0.2);
  border-color: rgba($info-color, 0.1);
}

.alert-warning {
  color: $warning-color;
  background-color: rgba($warning-color, 0.2);
  border-color: rgba($warning-color, 0.1);
}

.alert-danger {
  color: $error-color;
  background-color: rgba($error-color, 0.2);
  border-color: rgba($error-color, 0.1);
}

.alert-light {
  color: $reactify-light-btn-bg;
  background-color: rgba($reactify-light-btn-bg, 0.2);
  border-color: rgba($reactify-light-btn-bg, 0.1);
}

.alert-dark {
  color: $reactify-dark-btn-bg;
  background-color: rgba($reactify-dark-btn-bg, 0.2);
  border-color: rgba($reactify-dark-btn-bg, 0.1);
}

.alert-dismissible .close {
  text-shadow: initial;
}

.alert *[data-notify="title"] {
  display: block;
  font-size: 0.9rem;
}

div[data-notify="container"] {
  padding: 18px;
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.notification {
  box-sizing: border-box;
  padding: 15px 15px 15px 58px;
  border-radius: 2px;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

/* 18.Forms */
.av-invalid .av-label {
  color: $primary-color;
}

.av-tooltip .invalid-feedback {
  color: $reactify-foreground-color;
  background: $error-color;
  padding: 0.5rem 1rem;
  font-size: 0.76rem;
  text-align: center;
  position: absolute;
  z-index: 1;
  margin-top: -0.2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;

  &:after {
    content: "";
    position: absolute;
    top: -5px;
    left: -2.5px;
    margin-left: 50%;
    width: 10px;
    height: 5px;
    border-bottom: solid 5px $error-color;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
  }
}

.form-inline {
  .form-group {
    @include respond-below(xs) {
      width: 100%;
    }
  }
}

.form-check-label,
.custom-control-label {
  line-height: 24px;
}

.react-tagsinput {
  background-color: $reactify-input-background;
  border: 1px solid $reactify-separator-color;
  outline: initial;
  box-shadow: initial;
}

.react-tagsinput--focused {
  border: 1px solid rgba($reactify-theme-color-1, 0.6);
}

.react-tagsinput-tag {
  background: $reactify-theme-color-1;
  border-radius: 15px;
  padding: 0px 10px;
  margin-bottom: 0px;
  display: inline-block;
  font-size: 12px;
  color: $reactify-foreground-color;
  border: initial;
}

.form-control:focus {
  background: $reactify-input-background;
  color: $primary-color;
}

.bootstrap-tagsinput {
  width: 100%;
}

.bootstrap-tagsinput input {
  padding: 0;
}

.form-control:focus {
  border-color: rgba($reactify-theme-color-1, 0.6);
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.4rem + 3px);
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: rgba($primary-color, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
  background-color: transparent;
}

.custom-checkbox .custom-control-label.indeterminate::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='"+$reactify-btn-text-color+"' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-control-label,
.custom-control-input {
  outline: initial !important;
  box-shadow: initial !important;
}

.custom-control-input {
  left: 1px;
  top: 3px;
  opacity: 0;
  z-index: 1;
}

.custom-control-label::before {
  border: 1px solid $muted-color;
  background: initial;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  background-color: $reactify-theme-color-1;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $reactify-theme-color-1;
  box-shadow: initial !important;
  border: 1px solid $reactify-theme-color-1;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $reactify-theme-color-1;
  box-shadow: initial !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $reactify-theme-color-1;
  box-shadow: initial !important;
}

.custom-control-label::after,
.custom-control-label::before {
  box-shadow: initial !important;
}

.custom-control-label::before {
  top: 0.25rem;
}

.custom-control-label::after {
  top: 0.25rem;
}

.btn.rotate-icon-click i {
  transition: transform 0.5s;
}

.btn.rotate i {
  transform: rotate(180deg);
}

.btn .custom-control-label::before {
  border: 1px solid $reactify-btn-text-color;
}

.btn .custom-control-input:checked ~ .custom-control-label::before {
  border: 1px solid $reactify-btn-text-color;
}

.btn-group-icon {
  line-height: 22px;
}

.valid-tooltip,
.invalid-tooltip {
  border-radius: 15px;
  padding: 0.5rem 1rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.76rem;
}

.valid-tooltip {
  background-color: rgba(40, 167, 69, 1);
  margin-top: -0.2rem;
}

.invalid-tooltip {
  background-color: rgba(220, 53, 69, 1);
  margin-top: -0.2rem;
}

.valid-tooltip:after {
  content: "";
  position: absolute;
  top: -5px;
  left: -2.5px;
  margin-left: 50%;
  width: 10px;
  height: 5px;
  border-bottom: solid 5px rgba(40, 167, 69, 1);
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
}

.invalid-tooltip:after {
  content: "";
  position: absolute;
  top: -5px;
  left: -2.5px;
  margin-left: 50%;
  width: 10px;
  height: 5px;
  border-bottom: solid 5px rgba(220, 53, 69, 1);
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
}

.valid-icon {
  position: absolute;
  bottom: 8px;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0;
  font-size: 0.76rem;
  line-height: 1;
  border-radius: 0.2rem;
  right: 4px;
  color: $success-color;
}

.invalid-icon {
  position: absolute;
  bottom: 2px;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.2rem;
  right: 4px;
  color: $error-color;
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-icon,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-icon,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-icon,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-icon {
  display: block;
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-icon,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-icon,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-icon,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-icon {
  display: block;
}

.autosuggest {
  position: relative;
}

.react-autosuggest__suggestions-container {
  border-radius: $border-radius;
  z-index: 20;
  box-shadow: initial;
  margin-top: -1px;
  background: $reactify-foreground-color;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  top: 100%;
}

.react-autosuggest__suggestions-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.react-autosuggest__suggestion {
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  background: $reactify-foreground-color !important;
  color: $primary-color !important;

  &:hover,
  &:active {
    background: lighten($reactify-theme-color-1, 10%) !important;
    color: $reactify-foreground-color !important;
  }
}

.react-autosuggest__input--open {
  border: 1px solid rgba($reactify-theme-color-1, 0.6) !important;
}

.react-autosuggest__suggestions-container--open {
  border: 1px solid rgba($reactify-theme-color-1, 0.6);
  border-top: initial;
  border-width: 1px !important;
}

.react-select {
  outline: initial !important;
  box-shadow: none !important;
}

.react-select__value-container {
  outline: initial !important;
  box-shadow: none !important;
  padding: 0.45rem 0.75rem 0.4rem 0.75rem !important;
  // height: calc(2.4rem + 3px) !important;
  background: $reactify-foreground-color !important;
}

.react-select .react-select__dropdown-indicator {
  color: $secondary-color;
}

.react-select .react-select__menu-list {
  padding-bottom: 0;
  padding-top: 0;
}

.react-select .react-select__single-value,
.react-select .react-select__multi-value__label {
  color: $primary-color;
}

.react-select .react-select__dropdown-indicator,
.react-select
  .react-select__control--is-focused
  .react-select__dropdown-indicator,
.react-select .react-select__clear-indicator,
.react-select
  .react-select__control--is-focused
  .react-select__clear-indicator {
  outline: initial;
  box-shadow: initial;

  &:active,
  &:focus,
  &:hover {
    color: $reactify-theme-color-1 !important;
  }
}

.react-select__control {
  border-radius: $border-radius !important;
  border: 1px solid $reactify-separator-color !important;
  background: $reactify-input-background !important;
  outline: initial !important;
  box-shadow: none !important;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__dropdown-indicator svg {
  width: 15px;
  height: 15px;
}

.react-select__option {
  background: $reactify-foreground-color !important;
  color: $primary-color !important;

  &:hover,
  &:active {
    background: $reactify-theme-color-1 !important;
    color: $reactify-btn-text-color !important;
  }
}

.react-select__option--is-selected {
  background: $reactify-theme-color-1 !important;
  color: $reactify-btn-text-color !important;
}

.react-select__control--is-focused {
  border-color: rgba($reactify-theme-color-1, 0.6) !important;
}

.react-select__multi-value {
  background: transparent !important;
  border: 1px solid $reactify-separator-color;
}

.react-select__multi-value__remove:hover,
.react-select__multi-value__remove:active {
  background: transparent !important;
  color: $reactify-theme-color-1 !important;
}

.react-select .react-select__menu {
  border-radius: $border-radius !important;
  z-index: 20 !important;
  box-shadow: initial !important;
  border: 1px solid rgba($reactify-theme-color-1, 0.6) !important;
  border-top: initial !important;
  margin-top: -1px !important;
  background-color: $reactify-foreground-color;
  border-width: 1px !important;
}

.react-select__single-value {
  bottom: 0;
  top: 50%;
  padding-top: 2px;
}

.react-datepicker {
  background-color: $reactify-input-background;
  border: $reactify-separator-color;
}

.react-datepicker__input-container input {
  background-color: $reactify-input-background;
  border: $reactify-separator-color;
}

.react-datepicker__input-container input:focus {
  border-color: rgba($reactify-theme-color-1, 0.6) !important;
}

.react-datepicker-popper {
  z-index: 20;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__input-container input {
  font-size: 0.8rem;
  padding: 0.65rem 0.75rem 0.5rem 0.75rem !important;
  height: calc(2.4rem + 3px) !important;
  border: 1px solid $reactify-separator-color;
  border-radius: $border-radius;
  width: 100%;
  outline: initial;
}

.react-datepicker {
  border: 1px solid $reactify-separator-color;
  border-radius: $border-radius;
  font-family: "Nunito", sans-serif;
}

.react-datepicker__header {
  background-color: $reactify-input-background;
  border-bottom: initial;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 35px;
  height: 35px;
  line-height: 2.3rem;
  border-radius: 0 !important;
  margin: 0;
  outline: initial !important;
}

.react-datepicker__day:hover {
  background: $reactify-separator-color;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected {
  background: $reactify-theme-color-1;
}

.react-datepicker__day--today {
  font-weight: 400;
  color: $reactify-btn-text-color !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: $reactify-foreground-color;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: $reactify-separator-color;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  color: $primary-color;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: $primary-color;
}

.react-datepicker__input-container input {
  color: $primary-color;
}

.react-datepicker__time-container {
  border-left: 1px solid $reactify-separator-color;
  width: 71px;
}

.react-datepicker__time-container .react-datepicker__time {
  background-color: $reactify-input-background;
  color: $primary-color;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  color: $reactify-btn-text-color;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: $reactify-foreground-color;
}

.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-top-color: $reactify-separator-color;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: $reactify-background-color;
}

.react-datepicker__triangle {
  left: 30px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background: $reactify-theme-color-1;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background: $reactify-separator-color;
}

.react-datepicker.embedded {
  border: initial;
  width: 100%;

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 14.28%;
  }

  .react-datepicker__month-container {
    width: 100%;
  }
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 87px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding-left: 0;
  padding-right: 30px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: $reactify-theme-color-1;
}

.react-fine-uploader-gallery-dropzone,
.react-fine-uploader-gallery-nodrop-container {
  min-height: 118px;
  border: 1px solid $reactify-separator-color;
  background: $reactify-input-background;
  padding: 10px 10px;
  border-radius: $border-radius;
  color: $primary-color;
  padding: 0 !important;
}

.react-fine-uploader-gallery-dropzone-active {
  border: 1px solid rgba($reactify-theme-color-1, 0.6) !important;
}

.react-fine-uploader-gallery-dropzone {
  button svg {
    fill: $reactify-theme-color-1;
  }
}

.react-fine-uploader-gallery-file-input-container {
  display: inline-block;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute !important;
  margin: 0;
  padding: 0;
}

.react-fine-uploader-gallery-dropzone-content,
.react-fine-uploader-gallery-nodrop-content {
  font-size: 0.8rem;
  color: $primary-color;
  opacity: 1;
  top: 50%;
  transform: translateY(-50%);
}

.react-fine-uploader-gallery-files {
  padding: 10px;
  margin-bottom: 0;
}

.react-fine-uploader-gallery-file {
  display: flex;
  flex-direction: row;
  width: 260px;
  height: unset;
  box-shadow: initial !important;
  border-radius: $border-radius;
  font-size: 0.8rem;
  border: 1px solid $reactify-separator-color;
  float: left;
  margin: 16px;
}

.react-fine-uploader-gallery-thumbnail-container {
  display: block;
  height: unset !important;
  text-align: center;
  width: 30% !important;
  height: 62px !important;
  overflow: hidden;
}

.react-fine-uploader-gallery-progress-bar-container {
  width: 100%;
  height: 3px;
  background: $reactify-separator-color;
}

.react-fine-uploader-gallery-progress-bar,
.react-fine-uploader-gallery-total-progress-bar {
  background: $reactify-theme-color-1;
  box-shadow: initial;
}

.react-fine-uploader-gallery-status,
.react-fine-uploader-filesize-value,
.react-fine-uploader-filesize-unit {
  font-style: initial;
  color: $reactify-theme-color-1;
  font-size: 0.76rem;
}

.react-fine-uploader-filename.react-fine-uploader-gallery-filename {
  font-weight: normal;
}

.react-fine-uploader-gallery-total-progress-bar-container {
  display: none;
  background: $reactify-separator-color;
}

.react-fine-uploader-gallery-thumbnail {
  height: 100%;
}

.react-fine-uploader-gallery-upload-failed-icon,
.react-fine-uploader-gallery-upload-success-icon {
  height: 36px;
  width: 36px;
}

.react-fine-uploader-gallery-upload-failed-icon {
  fill: $reactify-btn-text-color;
  opacity: 1;
}

.react-fine-uploader-gallery-file-footer {
  width: 70%;
  padding: 15px;
  background: $reactify-foreground-color;
}

.react-fine-uploader-gallery-thumbnail-icon-backdrop {
  opacity: 0;
}

.react-fine-uploader-gallery-thumbnail-icon-backdrop,
.react-fine-uploader-gallery-upload-failed-icon,
.react-fine-uploader-gallery-upload-success-icon {
  left: 38px;
  position: absolute;
  top: 50%;
}

.dropzone .dz-message {
  text-align: center;
}

.dropzone.dz-clickable {
  cursor: pointer;
}

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  margin: 0;
}

.dropzone .dz-preview.dz-image-preview {
  width: 260px;
  height: unset;
  min-height: unset;
  border: 1px solid $reactify-separator-color;
  border-radius: $border-radius;
  background: $reactify-foreground-color;
  color: $primary-color;

  strong {
    font-weight: normal;
  }

  .remove {
    position: absolute;
    right: 5px;
    top: 5px;
    color: $reactify-theme-color-1;
  }

  .dz-details {
    position: static;
    display: block;
    opacity: 1;
    text-align: left;
    min-width: unset;
    z-index: initial;
    color: $primary-color;
  }

  .dz-error-mark {
    color: $reactify-btn-text-color;
    transform: translateX(-50%) translateY(-50%) !important;
    transition: initial !important;
    animation: initial !important;
    margin-left: 0;
    margin-top: 0;

    i {
      font-size: 26px;
    }
  }

  .dz-progress {
    width: 90%;
    margin-left: 0;
    margin-top: 0;
    left: 50%;
    right: 0;
    transform: translateX(-50%);
    height: 5px;

    .dz-upload {
      width: 100%;
      background: $reactify-theme-color-1;
    }
  }

  .dz-error-message {
    border-radius: 15px;
    background: $error-color;
    top: 60px;

    &:after {
      border-bottom: 6px solid $error-color;
    }
  }
}

.custom-switch {
  display: block;
  background: $reactify-separator-color;
  width: 58px;
  height: 27px;
  border: 1px solid $reactify-separator-color;

  &:after {
    width: 18px;
    height: 18px;
    top: 3px;
    margin-left: 3px;
    box-shadow: initial;
    transform: initial !important;
    animation-name: unset !important;
    background: $reactify-foreground-color;
  }

  &.rc-switch-checked:after {
    left: 30px;
  }

  &.rc-switch-checked.custom-switch-primary {
    background: $reactify-theme-color-1;
    border: 1px solid $reactify-theme-color-1;
  }

  &.rc-switch-checked.custom-switch-secondary {
    background: $reactify-theme-color-2;
    border: 1px solid $reactify-theme-color-2;
  }

  &.custom-switch-primary-inverse {
    border: 1px solid $reactify-separator-color;
  }

  &.rc-switch-checked.custom-switch-primary-inverse {
    background: $reactify-input-background;
    border: 1px solid $reactify-theme-color-1;

    &:after {
      background: $reactify-theme-color-1;
    }
  }

  &.custom-switch-secondary-inverse {
    border: 1px solid $reactify-separator-color;
  }

  &.rc-switch-checked.custom-switch-secondary-inverse {
    background: $reactify-input-background;
    border: 1px solid $reactify-theme-color-2;

    &:after {
      background: $reactify-theme-color-2;
    }
  }
}

.custom-switch .custom-switch-input + .custom-switch-btn {
  background: $reactify-separator-color;
}

.custom-switch.custom-switch-primary
  .custom-switch-input:checked
  + .custom-switch-btn {
  background: $reactify-theme-color-1;
  border: 1px solid $reactify-theme-color-1;
}

.custom-switch.custom-switch-secondary
  .custom-switch-input:checked
  + .custom-switch-btn {
  background: $reactify-theme-color-2;
  border: 1px solid $reactify-theme-color-2;
}

.custom-switch.custom-switch-primary-inverse
  .custom-switch-input
  + .custom-switch-btn {
  border: 1px solid $reactify-separator-color;
}

.custom-switch.custom-switch-primary-inverse
  .custom-switch-input:checked
  + .custom-switch-btn {
  background: $reactify-input-background;
  border: 1px solid $reactify-theme-color-1;
}

.custom-switch.custom-switch-primary-inverse
  .custom-switch-input:checked
  + .custom-switch-btn:after {
  background: $reactify-theme-color-1;
}

.custom-switch.custom-switch-secondary-inverse
  .custom-switch-input
  + .custom-switch-btn {
  border: 1px solid $reactify-separator-color;
}

.custom-switch.custom-switch-secondary-inverse
  .custom-switch-input:checked
  + .custom-switch-btn {
  background: $reactify-input-background;
  border: 1px solid $reactify-theme-color-2;
}

.custom-switch.custom-switch-secondary-inverse
  .custom-switch-input:checked
  + .custom-switch-btn:after {
  background: $reactify-theme-color-2;
}

.custom-switch .custom-switch-input + .custom-switch-btn:after {
  background: $reactify-input-background;
}

.custom-switch .custom-switch-input + .custom-switch-btn {
  border-color: $reactify-separator-color;
}

.input-group-text {
  border-radius: $border-radius;
  background-color: lighten($reactify-input-background, 5%);
  border-color: $reactify-separator-color;
  color: $primary-color;
  font-size: 0.8rem;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.form-control {
  border-radius: $border-radius;
  outline: initial !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  // padding: 0.75rem 0.75rem 0.5rem 0.75rem;
  padding: 0.7rem 0.75rem 0.65rem 0.75rem;
  line-height: 1.5;
  border: 1px solid $reactify-separator-color;
  background: $reactify-input-background;
  color: $primary-color;
}

@keyframes autofill {
  to {
    color: $primary-color;
    background: transparent;
  }
}

input:-webkit-autofill {
  animation-name: autofill;
  animation-fill-mode: both;
}

input:-webkit-autofill {
  -webkit-text-fill-color: $primary-color !important;
}

.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  border-radius: $border-radius;
}

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  border-radius: $border-radius;
}

.custom-select {
  border-radius: $border-radius;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  outline: initial !important;
  box-shadow: initial !important;
}

.custom-select {
  height: calc(2.5rem + 2px);
}

.custom-select:focus {
  border-color: $reactify-theme-color-1;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: rgba($reactify-theme-color-1, 0.6);
}

.custom-file-label::after {
  background: $reactify-foreground-color;
  color: $primary-color;
  border-color: $reactify-separator-color;
}

.custom-file-input {
  box-shadow: initial !important;
}

.custom-file-label {
  background: $reactify-foreground-color;
  border-color: $reactify-separator-color;
}

.custom-file-label {
  box-shadow: initial !important;
  border-radius: $border-radius;
  height: calc(2.5rem + 2px);
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.custom-file {
  height: calc(2.5rem + 2px);
}

.custom-file-label:focus,
.custom-file-input:focus {
  border-color: $reactify-theme-color-1;
}

.custom-file-label::after {
  height: calc(calc(2.5rem + 2px) - 1px * 2);
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.jumbotron {
  background: initial;
}

/* 19.Slider(Range) */
.rc-slider-tooltip {
  background: $reactify-input-background;
  color: $primary-color;
  border: 1px solid $reactify-separator-color;
  border-radius: $border-radius;
  text-align: center;
  top: 150%;
  bottom: unset;
  padding: 5px;
  transform: translateX(-50%);
}

.rc-slider-handle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: $reactify-foreground-color;
  cursor: default;
  border: 1px solid $reactify-separator-color !important;
  box-shadow: initial !important;
}

.rc-slider-track,
.rc-slider-rail,
.rc-slider-step {
  height: 7px;
}

.rc-slider-rail {
  border: 1px solid $reactify-separator-color;
  background: $reactify-foreground-color;
}

.rc-slider-handle {
  margin-top: -7px;
}

.rc-slider-track {
  background: $reactify-theme-color-1;
}

/* 20.Navs */
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: $reactify-foreground-color;
  background-color: $reactify-theme-color-1;
}

.nav-pills .nav-link {
  border-radius: 50px;
}

.nav-link {
  padding: 0.5rem 1rem;

  @include respond-below(sm) {
    padding: 0.5rem 0.5rem;
  }

  &.active {
    color: $reactify-theme-color-1;
  }
}

.page-item {
  padding: 10px;

  @include respond-below(sm) {
    padding: 3px;
  }

  .page-link {
    outline: initial !important;
    box-shadow: initial !important;
    line-height: 1.7;
    min-width: 38px;
    text-align: center;
    height: 38px;
    padding: 0.55rem 0;

    @include respond-below(xs) {
      min-width: 30px;
      height: 30px;
      line-height: 0.9;
      font-size: 0.76rem;
    }

    &.next {
      background: $reactify-theme-color-1;
      color: $reactify-foreground-color;
      border: 1px solid $reactify-theme-color-1;
    }

    &.prev,
    &.prev {
      background: $reactify-theme-color-1;
      border: 1px solid $reactify-theme-color-1;
      color: $reactify-foreground-color;
    }

    &.first,
    &.last {
      background: transparent;
      color: $reactify-theme-color-1;
      border: 1px solid $reactify-theme-color-1;
      border-radius: 30px;

      &:hover {
        background: $reactify-theme-color-1;
        color: $reactify-foreground-color;
        border: 1px solid $reactify-theme-color-1;
      }
    }

    &:hover {
      background-color: transparent;
      border-color: lighten($reactify-theme-color-1, 10%);
      color: $reactify-theme-color-1;
    }
  }

  &.active .page-link {
    background: transparent;
    border: 1px solid $reactify-theme-color-1;
    color: $reactify-theme-color-1;
  }

  &.disabled .page-link {
    border-color: $reactify-separator-color;
    color: $reactify-separator-color;
    background: transparent;
  }
}

.page-link {
  background-color: transparent;
  border-color: transparent;
  color: $primary-color;
}

.btn-sm.page-link {
  padding: 0.5rem 0.5rem;
}

.pagination-lg {
  .page-item {
    padding: 15px;

    @include respond-below(sm) {
      padding: 3px;
    }

    .page-link {
      min-width: 50px;
      height: 50px;

      @include respond-below(sm) {
        min-width: 38px;
        height: 38px;
        padding: 0.55rem 0;
        line-height: 1.5;
        font-size: 0.8rem;
      }

      @include respond-below(xs) {
        min-width: 30px;
        height: 30px;
        line-height: 0.9;
        font-size: 0.76rem;
      }
    }
  }
}

.pagination-sm {
  .page-item {
    padding: 6px;

    @include respond-below(sm) {
      padding: 3px;
    }

    .page-link {
      min-width: 30px;
      height: 30px;
      line-height: 0.9;
      font-size: 0.76rem;
    }
  }
}

/* 21.Tooltip and popover */
.popover {
  border-radius: $border-radius;
  background-color: $reactify-foreground-color;
  border-color: $reactify-separator-color;

  .popover-body {
    color: $primary-color;
  }
}

.popover-header {
  background-color: transparent;
  border-bottom: initial;
}

.tooltip-inner {
  padding: 0.5rem 0.75rem;
  color: $primary-color;
  background-color: $reactify-foreground-color;
  border-radius: 0.1rem;
  border: 1px solid $reactify-separator-color;
}

.tooltip.show {
  opacity: 1;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
  border-right-color: $reactify-separator-color;
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: $reactify-foreground-color;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
  border-left-color: $reactify-separator-color;
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: $reactify-foreground-color;
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: $reactify-separator-color;
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: $reactify-foreground-color;
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
  border-top-color: $reactify-separator-color;
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: $reactify-foreground-color;
}

.tooltip .arrow::before,
.tooltip .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: $reactify-separator-color;
}

.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: $reactify-foreground-color;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  border-width: 0.4rem 0.4rem 0.4rem 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: $reactify-separator-color;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}

.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: $reactify-foreground-color;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  border-width: 0.4rem 0.4rem 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: $reactify-separator-color;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: $reactify-foreground-color;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: $reactify-separator-color;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  right: 0;
  border-left-color: $reactify-foreground-color;
}

.search-sm {
  position: relative;

  input {
    background: none;
    outline: initial !important;
    border: 1px solid $secondary-color;
    border-radius: 15px;
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    font-size: 0.76rem;
    line-height: 1.3;
    color: $primary-color;
  }

  &:after {
    font-family: "simple-line-icons";
    content: "\e090";
    font-size: 14px;
    border-radius: 10px;
    color: $secondary-color;
    position: absolute;
    width: 25px;
    right: 2px;
    text-align: center;
    cursor: pointer;
    top: 2px;
  }
}

.notification {
  border-radius: $border-radius;
  @include depth(1);
  padding: 25px 25px 25px 25px;
}

.notification:before {
  content: "";
  display: none;
}

.notification-primary {
  color: $reactify-theme-color-1;
  background-color: $reactify-foreground-color;
  border: 1px solid $reactify-theme-color-1;
  opacity: 1;

  .notification-message {
    .title {
      color: $reactify-theme-color-1;
    }

    .message {
      color: $primary-color;
    }
  }

  &.filled {
    color: $reactify-foreground-color;
    background-color: $reactify-theme-color-1;

    .notification-message {
      .title {
        color: $reactify-foreground-color;
      }

      .message {
        color: $reactify-foreground-color;
      }
    }
  }
}

.notification-secondary {
  color: $reactify-theme-color-2;
  background-color: $reactify-foreground-color;
  border: 1px solid $reactify-theme-color-2;
  opacity: 1;

  .notification-message {
    .title {
      color: $reactify-theme-color-2;
    }

    .message {
      color: $secondary-color;
    }
  }

  &.filled {
    color: $reactify-foreground-color;
    background-color: $reactify-theme-color-2;

    .notification-message {
      .title {
        color: $reactify-foreground-color;
      }

      .message {
        color: $reactify-foreground-color;
      }
    }
  }
}

.notification-info {
  color: $info-color;
  background-color: $reactify-foreground-color;
  border: 1px solid $info-color;
  opacity: 1;

  .notification-message {
    .title {
      color: $info-color;
    }

    .message {
      color: $secondary-color;
    }
  }

  &.filled {
    color: $reactify-foreground-color;
    background-color: $info-color;

    .notification-message {
      .title {
        color: $reactify-foreground-color;
      }

      .message {
        color: $reactify-foreground-color;
      }
    }
  }
}

.notification-success {
  color: $success-color;
  background-color: $reactify-foreground-color;
  border: 1px solid $success-color;
  opacity: 1;

  .notification-message {
    .title {
      color: $success-color;
    }

    .message {
      color: $secondary-color;
    }
  }

  &.filled {
    color: $reactify-foreground-color;
    background-color: $success-color;

    .notification-message {
      .title {
        color: $reactify-foreground-color;
      }

      .message {
        color: $reactify-foreground-color;
      }
    }
  }
}

.notification-warning {
  color: $warning-color;
  background-color: $reactify-foreground-color;
  border: 1px solid $warning-color;
  opacity: 1;

  .notification-message {
    .title {
      color: $warning-color;
    }

    .message {
      color: $secondary-color;
    }
  }

  &.filled {
    color: $reactify-foreground-color;
    background-color: $warning-color;

    .notification-message {
      .title {
        color: $reactify-foreground-color;
      }

      .message {
        color: $reactify-foreground-color;
      }
    }
  }
}

.notification-error {
  color: $error-color;
  background-color: $reactify-foreground-color;
  border: 1px solid $error-color;
  opacity: 1;

  .notification-message {
    .title {
      color: $error-color;
    }

    .message {
      color: $secondary-color;
    }
  }

  &.filled {
    color: $reactify-foreground-color;
    background-color: $error-color;

    .notification-message {
      .title {
        color: $reactify-foreground-color;
      }

      .message {
        color: $reactify-foreground-color;
      }
    }
  }
}

/* 22.Progress */
.progressBarThick {
  height: 10px !important;
}
.progress {
  background-color: $reactify-separator-color-light;
  height: 3px;
}

.progress-bar {
  background-color: $reactify-theme-color-1;
}

.CircularProgressbar-text {
  fill: $primary-color !important;
}

// .progress-bar-circle {
//   width: 54px;
//   height: 54px;

//   svg path:first-of-type {
//     stroke: $reactify-separator-color;
//   }

//   svg path:last-of-type {
//     stroke: $reactify-theme-color-1;
//   }

//   &.progress-bar-banner {
//     svg path:first-of-type {
//       stroke: darken($reactify-theme-color-1, 15%);
//     }

//     svg path:last-of-type {
//       stroke: $reactify-btn-text-color;
//     }
//   }
// }

.progress-banner {
  height: 200px;
  background-image: linear-gradient(
    to right top,
    $reactify-gradient-color-2,
    $reactify-gradient-color-3,
    $reactify-gradient-color-1
  );
  transition: 0.5s;
  background-size: 200% auto;
  cursor: pointer;

  .CircularProgressbar .CircularProgressbar-text {
    fill: $reactify-btn-text-color !important;
  }

  .lead {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;

    @include respond-below(lg) {
      font-size: 1.2rem;
      margin-bottom: 0.2rem;
    }
  }

  i {
    font-size: 2.7rem;
    margin-bottom: 1rem;

    @include respond-below(lg) {
      font-size: 2rem;
      margin-bottom: 0.2rem;
    }
  }

  .progress-bar-circle.progress-bar-banner {
    width: 120px;
    height: 120px;

    svg path:last-of-type {
      stroke: darken($reactify-btn-text-color, 10%);
    }

    @include respond-below(lg) {
      width: 80px;
      height: 80px;
    }
  }

  .progress-bar-banner {
    .progressbar-text {
      color: $primary-color !important;
      font-size: 1.7rem;
      width: 110px;
      font-weight: 300;

      @include respond-below(lg) {
        font-size: 1.2rem;
        margin-bottom: 0.2rem;
      }
    }
  }

  &:hover {
    background-position: right top;
  }
}

/* 23.Rating */
.br-theme-bootstrap-stars .br-widget a.br-active:after {
  color: $reactify-theme-color-1;
}

.br-theme-bootstrap-stars .br-widget a.br-selected:after {
  color: $reactify-theme-color-1;
}

.react-rater {
  display: block !important;
  font-size: 16px;
  color: $reactify-separator-color;
}

.react-rater-star {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "simple-line-icons";
  color: $reactify-separator-color;
  margin-right: 3px;
  font-size: 18px;

  &.is-active,
  &.will-be-active {
    color: $reactify-theme-color-1;
  }

  &:after {
    content: "\f005";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 10px;
  }
}

/* 24.Sortable */
.sortable {
  cursor: default;
}

.sortable span {
  vertical-align: middle;
}

.sortable-ghost {
  opacity: 0.5;
}

/* 25.Modal */
.modal {
  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 1.75rem;
  }

  .modal-header {
    border-bottom: 1px solid $reactify-separator-color;
  }

  .modal-footer {
    border-top: 1px solid $reactify-separator-color;
  }

  .close {
    color: $primary-color;
    text-shadow: initial;
  }
}

.modal-content {
  border: initial;
  border-radius: 0.1rem;
  background: $reactify-foreground-color;
}

.modal-dialog {
  @include depth(2);
}

.modal-right {
  padding-right: 0 !important;

  .modal-dialog {
    margin: 0 auto;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    max-width: 380px;
  }

  .modal-content {
    min-height: 100%;
  }

  .modal-header {
    height: $navbar-height;

    @include respond-below(xl) {
      height: $navbar-height-lg;
    }

    @include respond-below(lg) {
      height: $navbar-height-md;
    }

    @include respond-below(sm) {
      height: $navbar-height-xs;
    }
  }

  .modal-footer {
    justify-content: center;
  }

  .modal.fade .modal-dialog {
    transform: translate(25%, 0) !important;
  }

  .modal.show .modal-dialog {
    transform: translate(0, 0) !important;
  }
}

/* 26.Authorization */
.loginBoxBg {
  background: url("/assets/img/loginbg.jpg") no-repeat center top;
  background-size: cover;
}
.logo-login-page {
  width: 250px;
}
.transparent-card {
  background-color: rgba(0, 0, 0, 0.5);
}
.margin-right-2px {
  border-right: 2px solid #f8f8f8;
}

/* 27.Icons */
.simple-line-icons .glyph,
.mind-icons .glyph {
  width: 14.28%;
  text-align: center;
  float: left;
  height: 100px;

  .glyph-icon,
  .fa {
    font-size: 32px;
  }

  .author-name {
    display: none;
  }

  .class-name {
    font-size: 0.76rem;
    color: $muted-color !important;
  }

  @include respond-below(lg) {
    width: 16.66%;
  }

  @include respond-below(md) {
    width: 20%;
  }

  @include respond-below(sm) {
    width: 25%;
  }

  @include respond-below(xs) {
    width: 50%;
  }
}

.chart-container {
  height: 300px;
}

.cursor-pointer {
  cursor: pointer;
}
.font-bold {
  font-weight: bold !important;
}
.reactify-theme-colors {
  .reactify-theme-button {
    position: absolute;
    left: -34px;
    background: $reactify-foreground-color;
    padding: 13px 7px 13px 7px;
    border-radius: 0.2rem;
    color: $primary-color;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
    font-size: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: $reactify-theme-color-1;
  }

  .reactify-theme-color {
    width: 34px;
    height: 34px;
    display: inline-block;
    transition: background 0.25s;
    content: "\f004";
    font-family: "Font Awesome 5 Free";
    font-weight: 200;

    &.active,
    &:hover {
      background: $reactify-foreground-color;
    }
    &:hover::before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      margin-left: 7px;
      font-size: 17px;
    }
  }

  .reactify-theme-color-purple {
    border: 3px solid $reactify-theme-color-purple;
  }

  .reactify-theme-color-blue {
    border: 3px solid $reactify-theme-color-blue;
  }

  .reactify-theme-color-orange {
    border: 3px solid $reactify-theme-color-orange;
  }

  .reactify-theme-color-green {
    border: 3px solid $reactify-theme-color-green;
  }

  .reactify-theme-color-red {
    border: 3px solid $reactify-theme-color-red;
  }

  &.shown {
    transform: translate(0, -50%);
  }
}

/* 28.Floating Label */
.has-float-label {
  display: block;
  position: relative;
}

.has-float-label label::after,
.has-float-label > span::after {
  background: $reactify-foreground-color !important;
}

.has-top-label label,
.has-top-label > span,
.has-float-label label,
.has-float-label > span {
  color: rgba($primary-color, 0.7);
}

.has-float-label label,
.has-float-label > span:last-of-type {
  position: absolute;
  cursor: text;
  font-size: 90%;
  opacity: 1;
  top: -0.4em;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
}

.has-float-label label::after,
.has-float-label > span::after {
  content: " ";
  display: block;
  position: absolute;
  height: 5px;
  top: 3px;
  left: -0.2em;
  right: -0.2em;
  z-index: -1;
}

.has-float-label .form-control::-webkit-input-placeholder {
  opacity: 1;
}

.has-float-label .form-control::-moz-placeholder {
  opacity: 1;
}

.has-float-label .form-control:-ms-input-placeholder {
  opacity: 1;
}

.has-float-label .form-control::placeholder {
  opacity: 1;
}

.has-float-label
  .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}

.has-float-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0;
}

.has-float-label
  .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0;
}

.has-float-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}

.input-group .has-float-label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.input-group .has-float-label .form-control {
  width: 100%;
}

.input-group .has-float-label:not(:last-child),
.input-group .has-float-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}

.input-group .has-float-label:not(:first-child),
.input-group .has-float-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.form-group.has-top-label .form-control,
.form-group.has-top-label .react-tagsinput,
.form-group.has-top-label .react-datepicker__input-container input,
.form-group.has-top-label .react-select__value-container {
  padding: 1.7rem 0.75rem 0.5rem 0.75rem !important;
}

.has-top-label {
  display: block;
  position: relative;

  .react-tagsinput-input {
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
    line-height: 1;
  }
}

.has-top-label .react-select__value-container .css-rsyb7x {
  margin: 0 !important;
  padding: 0 !important;
}

.has-top-label label,
.has-top-label > span:last-of-type {
  position: absolute;
  cursor: text;
  font-size: 76%;
  opacity: 1;
  top: 0.7rem;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
  font-weight: 600;
}

.has-top-label label::after,
.has-top-label > span::after {
  content: " ";
  display: block;
  position: absolute;
  height: 2px;
  top: 50%;
  left: -0.2em;
  right: -0.2em;
  z-index: -1;
}

.has-top-label .form-control::-webkit-input-placeholder {
  opacity: 1;
}

.has-top-label .form-control::-moz-placeholder {
  opacity: 1;
}

.has-top-label .form-control:-ms-input-placeholder {
  opacity: 1;
}

.has-top-label .form-control::placeholder {
  opacity: 1;
}

.has-top-label
  .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}

.has-top-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0;
}

.has-top-label
  .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0;
}

.has-top-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}

.has-top-label .form-control:placeholder-shown:not(:focus) + * {
  font-size: 150%;
  opacity: 0.5;
  top: 0.3em;
}

.has-top-label .react-select__single-value {
  top: unset !important;
  bottom: -3px !important;
  margin-left: 0 !important;
}

.input-group .has-top-label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.input-group .has-top-label .form-control {
  width: 100%;
}

.input-group .has-top-label:not(:last-child),
.input-group .has-top-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}

.input-group .has-top-label:not(:first-child),
.input-group .has-top-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.form-group.has-top-label .form-control,
.form-group.has-top-label .react-tagsinput,
.form-group.has-top-label .react-datepicker__input-container input,
.form-group.has-top-label .react-select__value-container {
  min-height: calc(3.3rem + 2px) !important;
}

.form-group.has-top-label select.form-control:not([size]):not([multiple]) {
  height: calc(3.3rem + 2px);
  padding: 1.7rem 0.75rem 0.5rem 0.5rem;
}

/* 29.Context Menu */
.react-contextmenu {
  box-shadow: initial;
  border-radius: $border-radius;
  background: $reactify-input-background;
  border: 1px solid rgba($primary-color, 0.15);
  padding: 0.5rem 0;
  opacity: 0;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  padding: 0.5rem 1.5rem;
  background: $reactify-input-background;
  color: $primary-color;
  cursor: pointer;

  span {
    font-family: "Nunito", sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    margin-left: 0.5rem;
    line-height: 15px;
  }

  &:hover {
    color: $primary-color;
    text-decoration: none;
    background-color: $reactify-background-color;
  }
}

.react-contextmenu-item:not(.react-contextmenu-item--disabled):hover {
  color: $primary-color;
  text-decoration: none;
  background-color: $reactify-background-color;
}

.card.react-contextmenu--visible,
.card.active {
  @include depth(2);
}

/* Videojs */
.video-js .vjs-big-play-button {
  background: $reactify-input-background;
  height: 1.2em;
  border-radius: 0.75em;
  line-height: initial;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.5em;
  width: 2.5em;
  border: 0.06666em solid $reactify-input-background;

  .vjs-icon-placeholder {
    color: $reactify-theme-color-1;
  }
}

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
  background-color: darken($reactify-input-background, 5%);
  border-color: darken($reactify-input-background, 5%);
}

.vjs-control {
  text-shadow: initial !important;
  outline: initial !important;
}

.video-js .vjs-control-bar {
  background: initial;
  margin: 1.75rem;
  width: calc(100% - 3.5rem);

  .vjs-control.vjs-button,
  .vjs-remaining-time,
  .vjs-volume-panel {
    margin-right: 0.5em;
    background: $reactify-input-background;
    color: $reactify-theme-color-1;
    border-radius: 15px;
    @include depth(2);
  }

  .vjs-progress-control.vjs-control {
    background: $reactify-input-background;
    border-radius: 15px;
    margin-right: 0.5em;
    @include depth(2);
  }

  .vjs-mute-control.vjs-control {
    box-shadow: initial;
  }

  .vjs-progress-holder {
    height: 3px;
    font-size: 1.6em !important;
  }

  .vjs-load-progress,
  .vjs-load-progress div {
    background-color: rgba($reactify-theme-color-1, 0.2);
  }

  .vjs-play-progress:before {
    font-size: 0.55em;
    top: -0.2em;
  }

  .vjs-progress-holder {
    margin: 0 17px;
  }

  .vjs-slider {
    text-shadow: initial !important;
    outline: initial !important;
    background-color: darken($reactify-separator-color-light, 10%);
  }

  .vjs-play-progress {
    background: $reactify-theme-color-1;
  }

  .vjs-play-progress:before {
    color: $reactify-theme-color-1;
  }

  .vjs-volume-horizontal {
    margin-left: -1.5em;
    width: 4em;
  }

  .vjs-volume-panel .vjs-volume-level {
    background: $reactify-theme-color-1;
  }
}

.video-js.audio {
  background: initial;

  .vjs-big-play-button {
    display: none;
  }

  .vjs-control-bar {
    display: flex;
  }

  .vjs-fullscreen-control {
    display: none;
  }

  .vjs-control-bar {
    margin-bottom: 0;
  }

  .vjs-control.vjs-button,
  .vjs-remaining-time,
  .vjs-volume-panel {
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.15), 0 0px 1px rgba(0, 0, 0, 0.2);
  }

  .vjs-progress-control.vjs-control {
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.15), 0 0px 1px rgba(0, 0, 0, 0.2);
  }

  .vjs-mute-control {
    box-shadow: initial !important;
  }

  .vjs-loading-spinner {
    display: none !important;
  }
}

/* 38.Landing Page */
.landing-page {
  font-size: 1rem;
  line-height: 1.6rem;
  color: $secondary-color;

  @include respond-below(sm) {
    font-size: 0.9rem;
    line-height: 1.4rem;
  }

  .mobile-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    transform: translateX(300px);
    z-index: 25;
    width: 300px;
    background: $reactify-background-color;
    box-shadow: initial;
    transition: transform 0.5s, box-shadow 0.5s;
  }

  .logo-mobile {
    display: block;
    text-align: center;
    padding: 3rem 2rem;

    span {
      display: inline-block;
      width: 120px;
      height: 30px;
      background: url($lpgetLogoPinned);
      background-repeat: no-repeat;
    }
  }

  .main-container {
    overflow: hidden;
  }

  &.show-mobile-menu {
    .mobile-menu {
      transform: translateX(0);
      @include depth(2);
    }

    .main-container {
      .content-container,
      .landing-page-nav {
        transform: translateX(-300px);
      }
    }
  }

  .content-container {
    transition: transform 0.5s;
  }

  p {
    font-size: 1rem;
    line-height: 1.6rem;
    color: $secondary-color;

    @include respond-below(sm) {
      font-size: 0.9rem;
      line-height: 1.4rem;
    }
  }

  h1 {
    font-size: 3rem;
    color: $reactify-theme-color-1;
    margin-bottom: 2rem;

    @include respond-below(sm) {
      font-size: 2rem;
    }
  }

  h2 {
    font-size: 1.8rem;
    color: $reactify-theme-color-1;
    margin-bottom: 2rem;

    @include respond-below(md) {
      font-size: 1.6rem;
    }

    @include respond-below(sm) {
      font-size: 1.2rem;
    }
  }

  h3 {
    font-size: 1.4rem;
    line-height: 1.4;
    color: $reactify-theme-color-1;

    @include respond-below(sm) {
      font-size: 1.1rem;
    }
  }

  .semi-rounded {
    border-radius: 1.2rem !important;
  }

  .dropdown-toggle::after {
    opacity: 0.7;
  }

  .btn-outline-semi-light {
    border: 1px solid rgba($reactify-btn-text-color, 0.3);
    color: $reactify-btn-text-color;
    background: transparent;

    &:hover,
    &:active {
      border: 1px solid darken($reactify-theme-color-1, 10%);
      background: darken($reactify-theme-color-1, 10%);
    }
  }

  .landing-page-nav {
    z-index: 5 !important;
    top: 0;
    position: fixed;
    width: 100%;
    background: transparent;
    box-shadow: initial;
    transition: transform 0.5s;

    .headroom {
      transition: top 0.5s, background 0.5s, box-shadow 0.2s, opacity 0.2s,
        transform 0.5s !important;
    }

    .container {
      height: $main-menu-width;

      @include respond-below(xl) {
        height: $main-menu-width-lg;
      }

      @include respond-below(lg) {
        height: $main-menu-width-md;
      }

      @include respond-below(sm) {
        height: $main-menu-width-xs;
      }
    }

    .btn {
      padding-right: 1.75rem !important;
      padding-left: 1.75rem !important;
    }

    .navbar-logo {
      width: 120px;
      height: 40px;

      .white {
        display: inline-block;
        width: 120px;
        height: 40px;
        background: url($lpgetLogo);
        background-repeat: no-repeat;
      }

      .dark {
        width: 120px;
        height: 40px;
        background: url($lpgetLogoPinned);
        background-repeat: no-repeat;
        display: none;
      }
    }

    .mobile-menu-button {
      font-size: 20px;
      display: none;

      @include respond-below(md) {
        display: block;
      }
    }

    .navbar-nav {
      height: 100%;
      position: relative;

      li {
        height: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        position: relative;

        &:last-of-type {
          padding-right: 0;
        }

        &.active::before {
          content: " ";
          background: $reactify-btn-text-color;
          border-radius: 10px;
          position: absolute;
          width: calc(100% - 1.5rem);
          height: 4px;
          left: 0;
          right: 0;
          margin: 0 auto;
          top: 0;
        }

        @include respond-below(lg) {
          padding-left: 1rem;
          padding-right: 1rem;

          &:last-of-type {
            padding-right: 0;
          }
        }
      }

      .nav-item > a,
      .nav-item > .dropdown > .dropdown-toggle {
        color: $reactify-btn-text-color;
        font-family: Nunito, sans-serif;
        font-size: 1rem;
        display: inline-block;
        transform: translateY(-50%);
        margin-top: 57px;

        @include respond-below(xl) {
          margin-top: 53px;
        }

        @include respond-below(lg) {
          margin-top: 50px;
        }
      }

      .nav-item > .dropdown > .dropdown-toggle {
        padding: 0 !important;
      }

      .nav-item:not(.active) > a,
      .nav-item:not(.active) .dropdown > .dropdown-toggle {
        &:hover:not(.btn),
        &:active:not(.btn) {
          opacity: 0.8;
        }
      }

      .dropdown {
        height: 80px;
        position: relative;

        &:hover > .dropdown-menu {
          display: block;
        }

        & > .dropdown-toggle:active {
          pointer-events: none;
        }

        .dropdown-menu {
          position: absolute;
          margin-top: 0;
        }

        a.dropdown-item {
          font-size: 0.8rem;
          color: $primary-color;
          padding-bottom: 0.5rem;
          outline: initial;

          &.active,
          &:active {
            color: $reactify-btn-text-color;
          }
        }
      }
    }
  }

  .landing-page-nav .headroom--unpinned {
    transform: translate3d(0px, -100%, 0px) !important;
  }

  .landing-page-nav .headroom--scrolled.headroom--pinned {
    background: $reactify-foreground-color;
    @include depth(1);

    .navbar-logo .white {
      display: none;
    }

    .navbar-logo .dark {
      display: inline-block;
    }

    .navbar-nav {
      a,
      .dropdown-toggle {
        color: $primary-color;

        &:hover,
        &:active {
          color: $reactify-theme-color-1;
        }
      }

      .dropdown-item {
        color: $primary-color;

        &:focus {
          color: $reactify-btn-text-color;
        }
      }

      li {
        &.active::before {
          content: " ";
          background-color: $reactify-theme-color-1;
        }
      }

      .btn-outline-semi-light {
        border: 1px solid rgba($reactify-theme-color-1, 0.3);
        color: $reactify-theme-color-1;

        &:hover,
        &:active {
          border: 1px solid $reactify-theme-color-1;
          background: $reactify-theme-color-1;
          color: $reactify-btn-text-color;
        }
      }
    }

    &.headroom--unpinned {
      background: transparent;
    }
  }

  .landing-page-nav .headroom--unfixed {
    .mobile-menu-button {
      color: $reactify-btn-text-color;
    }
  }

  .section {
    margin-bottom: 150px;
    padding-top: 100px;
    padding-bottom: 100px;

    @include respond-below(md) {
      margin-bottom: 100px;
      padding-top: 60px;
    }

    &.home {
      background: url($lpBackgroundHome);
      background-repeat: no-repeat;
      background-position-y: 0;
      background-color: transparent;
      height: 1440px;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      position: relative;

      @include respond-below(xs) {
        background-position-y: -100px;
        height: 1340px;
      }
    }

    &.subpage {
      background: url($lpBackgroundSub);
      height: 690px;
      background-position-y: -350px;
      background-repeat: no-repeat;

      @include respond-below(xl) {
        background-position-y: -400px;
        height: 640px;
      }

      @include respond-below(md) {
        background-position-y: -540px;
        height: 520px;
      }

      @include respond-below(sm) {
        background-position-y: -620px;
        height: 450px;
      }
    }

    &.subpage-long {
      background: url($lpBackgroundSub);
      height: 900px;
      background-repeat: no-repeat;
      background-position-y: -180px;

      @include respond-below(md) {
        background-position-y: -220px;
        height: 830px;
      }

      @include respond-below(sm) {
        background-position-y: -310px;
        height: 760px;
      }
    }

    &.footer {
      background: url($lpBackgroundFooter);
      background-color: $reactify-foreground-color;
      background-repeat: no-repeat;
      padding-bottom: 0;
    }
  }

  .section.background {
    background: $reactify-foreground-color;
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;

    @include respond-below(md) {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    &::before {
      content: " ";
      width: 100%;
      height: 70px;
      position: absolute;
      top: -70px;
      background: url($lpBackgroundEllipsisTop);
      background-size: cover;
      background-position: center;
    }

    &::after {
      content: " ";
      width: 100%;
      height: 70px;
      position: absolute;
      bottom: -70px;
      background: url($lpBackgroundEllipsisBottom);
      background-size: cover;
      background-position: center;
    }

    &.background-no-bottom {
      &::after {
        content: "";
        background: initial;
        width: initial;
        height: initial;
      }
    }
  }

  .background-white {
    background: $reactify-btn-text-color;
  }

  .mobile-hero {
    margin-left: 50%;
    transform: translateX(-50%);
    max-width: 135%;
    margin-bottom: 3rem;
  }

  .home-row {
    padding-top: 180px;
    margin-bottom: 70px;

    @include respond-below(xl) {
      padding-top: 120px;
    }

    @include respond-below(md) {
      padding-top: 110px;
    }
  }

  .home-text {
    margin-top: 40px;

    p {
      color: $reactify-btn-text-color;
      font-size: 1.1rem;

      @include respond-below(sm) {
        font-size: 0.9rem;
        line-height: 1.4rem;
      }
    }

    .display-1 {
      font-size: 3rem;
      line-height: 3.2rem;
      margin-bottom: 1.1em;
      color: $reactify-btn-text-color;

      @include respond-below(md) {
        font-size: 1.8rem;
        line-height: 2.5rem;
      }

      @include respond-below(sm) {
        font-size: 1.7rem;
        line-height: 2.3rem;
      }

      @include respond-below(xs) {
        font-size: 1.6rem;
        line-height: 2.2rem;
      }
    }

    @include respond-below(sm) {
      margin-top: 0;
    }
  }

  .home-carousel {
    .card {
      .detail-text {
        padding: 1rem;
        margin-bottom: 0;
        min-height: 110px;

        @include respond-below(sm) {
          padding: 0.25rem;
          min-height: 55px;
        }
      }

      .btn-link {
        @include respond-below(sm) {
          padding: 0.25rem;
        }
      }
    }
  }

  i.large-icon {
    font-size: 60px;
    line-height: 110px;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#{$reactify-theme-color-2}),
      to(#{$reactify-theme-color-1})
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: initial;

    @include respond-below(sm) {
      line-height: 90px;
    }
  }

  .btn-circle {
    padding: 0;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    text-align: center;
    font-size: 14px;
  }

  .btn-circle.hero-circle-button {
    position: absolute;
    bottom: 80px;
    margin-left: 10px;

    i {
      padding-top: 18px;
      display: inline-block;
    }
  }

  .btn-link {
    text-decoration: initial !important;
  }

  .newsletter-input-container {
    margin-top: 5rem;

    .input-group {
      border-radius: 50px;
      @include depth(2);
    }

    input {
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      border-color: transparent !important;
      padding: 0.75rem 1.75rem 0.75rem;
      font-size: 0.9rem;
    }

    button {
      @include respond-below(sm) {
        padding: 1rem 1.5rem 0.9rem;
      }
    }
  }

  .footer {
    .footer-content {
      padding-top: 210px;

      @include respond-below(sm) {
        padding-top: 140px;
      }

      @include respond-below(xs) {
        padding-top: 80px;
      }
    }

    .footer-circle-button {
      margin-top: 80px;
      margin-right: 10px;

      @include respond-below(md) {
        margin-top: 110px;
      }

      i {
        padding-top: 15px;
        display: inline-block;
      }
    }

    .footer-logo {
      width: 140px;
      margin-bottom: 70px;
    }

    .footer-menu {
      p {
        color: $reactify-btn-text-color;
        font-size: 1rem;
      }

      a {
        cursor: pointer;
        font-size: 1rem;
        color: $reactify-btn-text-color;

        &:hover,
        &:focus {
          color: darken($reactify-theme-color-1, 20%);
        }
      }

      .collapse-button {
        i {
          font-size: 0.75rem;
          margin-left: 5px;
        }

        &:hover,
        &:focus {
          color: $reactify-btn-text-color;
        }
      }
    }

    .separator {
      opacity: 0.3;
    }

    .copyright {
      p {
        color: $reactify-btn-text-color;
      }
    }

    .social-icons {
      ul {
        margin-bottom: 0;
      }

      li {
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        a {
          color: $reactify-btn-text-color;
          font-size: 1.2rem;

          &:hover,
          &:active {
            color: rgba($reactify-btn-text-color, 0.7);
          }
        }
      }
    }
  }

  .feature-image-left {
    float: right;
  }

  .feature-image-left,
  .feature-image-right {
    @include respond-below(sm) {
      margin-left: 50%;
      transform: translateX(-50%);
      max-width: 115%;
      float: initial;
    }
  }

  .heading-team {
    @include respond-below(sm) {
      text-align: center;
    }
  }

  .mobile-menu {
    text-align: center;

    img {
      width: 100px;
      margin-top: 3rem;
      margin-bottom: 3rem;
    }

    ul {
      text-align: left;

      li {
        padding: 0.5rem 2.5rem;

        &.active {
          a {
            color: $reactify-theme-color-1;
          }
        }

        a {
          font-size: 0.9rem;
        }
      }
    }

    .dropdown {
      .dropdown-menu {
        position: static !important;
        background: initial;
        padding: initial;
        margin: initial;
        transform: initial !important;
        margin-top: 0.5rem;
      }

      a.dropdown-item {
        color: $primary-color;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        background: initial !important;

        &.active,
        &:active {
          color: $reactify-theme-color-1;
        }
      }
    }
  }

  .side-bar {
    @include respond-below(md) {
      margin-top: 5rem;
    }

    .side-bar-content {
      margin-bottom: 5rem;
    }

    h2 {
      @include respond-below(sm) {
        margin-bottom: 1rem;
      }
    }
  }

  .listing-card-container {
    background: initial;
    box-shadow: initial;

    .listing-desc {
      line-height: 1.4;
      font-size: 1rem;
    }
  }

  .table-heading {
    box-shadow: initial;
    background: initial;
  }

  form.dark-background {
    input:-webkit-autofill {
      color: white !important;
      -webkit-text-fill-color: white !important;
    }

    .has-float-label > span,
    .has-float-label label,
    .has-top-label > span,
    .has-top-label label {
      color: rgba($reactify-btn-text-color, 0.6);
    }

    .bootstrap-tagsinput,
    .form-control {
      background: transparent;
      border-color: rgba($reactify-btn-text-color, 0.3);
      color: $reactify-btn-text-color;

      &:focus,
      &:active {
        border-color: rgba($reactify-btn-text-color, 0.6);
      }
    }
  }

  .has-float-label > span,
  .has-float-label label,
  .has-top-label > span,
  .has-top-label label {
    font-size: 64%;
  }

  .video-js.blog-video {
    width: 100%;
    height: 442px;
    background-color: $reactify-background-color;

    @include respond-below(md) {
      height: 280px;
    }

    .vjs-poster {
      background-size: cover;
    }
  }

  .video-js.side-bar-video {
    width: 100%;
    height: 280px;
    background-color: $reactify-background-color;
    overflow: hidden;

    video {
      object-fit: cover;
    }

    .vjs-poster {
      background-size: cover;
    }
  }

  .feature-icon-container {
    .detail-text {
      min-height: 100px;

      @include respond-below(md) {
        min-height: 70px;
      }
    }
  }

  .screenshots {
    .nav-tabs {
      margin-bottom: 0 !important;
      border: initial;

      .nav-link {
        cursor: pointer;
        border: initial;
        background: initial !important;
        padding-right: 40px;
        padding-left: 40px;
      }

      .nav-item.show .nav-link:before,
      .nav-link.active:before {
        top: initial;
        bottom: 0;
      }
    }
  }

  .app-image {
    width: 100%;
    @include depth(2);
    border-radius: 1.2rem;
  }

  .doc-search {
    border: 1px solid rgba($reactify-btn-text-color, 0.3);
    background: transparent;
    border-radius: 40px;
    padding: 0.85rem 0.75rem 0.8rem;
    max-width: 360px;

    input {
      color: $reactify-btn-text-color;
      background: transparent;
      width: 93%;
      padding: 0 0.75rem;
      outline: initial !important;
      border: initial;

      &::placeholder {
        color: $reactify-btn-text-color;
        opacity: 0.7;
      }
    }

    i {
      font-size: 16px;
      color: rgba($reactify-btn-text-color, 0.7);
    }
  }

  .feedback-container {
    button {
      font-size: 1.3em;
      color: $secondary-color;
      margin: 0.5rem;

      &:hover,
      &:active {
        color: $reactify-theme-color-1;
      }
    }
  }

  .page-item .page-link {
    line-height: 1.2;
  }

  .review-carousel {
    margin-top: 5rem;

    .react-siema-container > div {
      display: flex;
    }
  }
}

/*Prices*/
.price-container {
  .price-item {
    .card {
      height: 100%;
    }

    .card-body {
      text-align: center;

      @include respond-below(md) {
        text-align: left;
      }

      @include respond-below(xs) {
        text-align: center;
      }
    }

    .price-top-part {
      text-align: center;

      @include respond-below(md) {
        padding-left: 0;
        padding-right: 0;
        width: 40%;
      }

      @include respond-below(xs) {
        width: initial;
      }
    }

    .price-feature-list {
      justify-content: space-between;

      @include respond-below(md) {
        min-height: 220px;
      }

      ul {
        margin: 0 auto;
        align-self: flex-start;
        margin-bottom: 1rem;

        @include respond-below(md) {
          margin-left: 0;
        }

        @include respond-below(xs) {
          margin: 0 auto;
        }
      }

      a {
        @include respond-below(md) {
          padding-left: 0;
        }

        @include respond-below(xs) {
          padding-left: 2.6rem;
        }
      }
    }
  }
}

.feature-row {
  margin-top: 80px;

  @include respond-below(sm) {
    margin-top: 40px;
  }
}

/* Timeline */

.timeline {
  list-style: none;
  padding: 10px 0;
  position: relative;
  font-weight: 300;

  &:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 2px;
    background: $reactify-foreground-color;
    left: 50%;
    margin-left: -1.5px;

    @include respond-below(md) {
      left: 45px;
    }
  }

  & > li {
    margin-bottom: 120px;
    position: relative;
    width: 50%;
    float: left;
    clear: left;

    @include respond-below(md) {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  & > li:before,
  & > li:after {
    content: " ";
    display: table;
  }

  & > li:after {
    clear: both;
  }

  & > li:before,
  & > li:after {
    content: " ";
    display: table;
  }

  & > li:after {
    clear: both;
  }

  & > li > .timeline-panel {
    width: calc(100% - 60px);
    float: left;
    position: relative;

    @include respond-below(md) {
      float: right;
      width: calc(100% - 120px);
      margin-right: 15px;
    }
  }

  & > li > .timeline-panel:after {
    position: absolute;
    top: 16px;
    right: -7px;
    display: inline-block;
    border-top: 7px solid transparent;
    border-left: 7px solid $reactify-foreground-color;
    border-right: 0 solid $reactify-foreground-color;
    border-bottom: 7px solid transparent;
    content: " ";

    @include respond-below(md) {
      border-left-width: 0;
      border-right-width: 7px;
      left: -7px;
      right: auto;
    }
  }

  & > li.timeline-inverted > .timeline-panel {
    float: right;
  }

  & > li.timeline-inverted > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 7px;
    left: -7px;
    right: auto;
  }

  & > li.timeline-inverted > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 7px;
    left: -7px;
    right: auto;
  }

  .timeline-badge > a {
    color: $reactify-foreground-color !important;
  }

  & > li.timeline-inverted {
    float: right;
    clear: right;
  }

  & > li:nth-child(2) {
    margin-top: 120px;

    @include respond-below(md) {
      margin-top: 0;
    }
  }

  .no-float {
    float: none !important;
  }

  & > li > .timeline-badge {
    color: $reactify-foreground-color;
    width: 60px;
    height: 60px;
    font-size: 14px;
    text-align: center;
    position: absolute;
    background-color: $reactify-theme-color-1;
    right: -30px;
    border-radius: 30px;
    padding-top: 18px;

    @include respond-below(md) {
      left: 15px;
      right: initial;
    }
  }

  & > li.timeline-inverted > .timeline-badge {
    right: initial;
    left: -30px;

    @include respond-below(md) {
      left: 15px;
    }
  }
}

// Timeline

.border-left-timeline {
  border-left: 2px solid #d7d7d7;
}
.border-left-timeline::before {
  border-left: 2px solid #333;
}
.timeline-icon {
  margin-left: -12px;
  font-size: 20px;
  margin-top: 10px;
}
.timeline-date {
  margin-left: -57px;
}
/* 14.Todo App*/
.heading-icon {
  font-size: 20px;
  color: $reactify-theme-color-1;
  margin-right: 5px;
  line-height: inherit;
  vertical-align: middle;
}

.todo-details {
  margin-top: 20px;
  margin-left: 40px;
}

.heading-number {
  border: 1px solid $reactify-theme-color-1;
  padding: 4px;
  vertical-align: middle;
  margin-right: 10px;
  border-radius: 20px;
  width: 34px;
  height: 34px;
  text-align: center;
  color: $reactify-theme-color-1;
}
/* 11.emails*/
.emails-menu {
  z-index: 1;
  @include depth(1);
  float: left;
  background: $reactify-foreground-color;
  transition: transform $menu-collapse-time;
  height: 500px;
  right: 0;
  top: $navbar-height;
  width: $emails-menu-width;

  @include respond-below(xl) {
    top: $navbar-height-lg;
    height: 500px;
  }

  @include respond-below(lg) {
    top: $navbar-height-md;
    transform: translateX($emails-menu-transform);
    height: 500px;
    position: relative;
  }

  @include respond-below(sm) {
    top: $navbar-height-xs;
    height: 500px;
    position: relative;
  }

  .scrollbar-container {
    margin-right: unset;
    padding-right: unset;

    .ps__rail-y {
      right: 2px !important;
    }
  }

  &.shown {
    transform: translateX(0);
  }

  .emails-menu-button {
    cursor: pointer;
    position: absolute;
    right: -22px;
    background: $reactify-foreground-color;
    top: $main-margin - 15;
    padding: 12px 8px 12px 5px;
    border-radius: 0.2rem;
    color: $secondary-color !important;
    font-size: 15px;
    line-height: 0;

    @include respond-below(xl) {
      top: $main-margin-lg - 14;
    }

    @include respond-below(lg) {
      top: $main-margin-md - 13;
    }

    @include respond-below(sm) {
      top: $main-margin-xs - 2;
    }
  }

  ul {
    li {
      margin-bottom: 5px;

      a {
        font-size: 13px;
        display: block;
        padding: 3px 0;

        &:hover i {
          color: $reactify-theme-color-1;
        }
      }

      i {
        font-size: 1.2em;
        margin-right: 10px;
        color: $secondary-color;
        vertical-align: unset;

        @include respond-below(sm) {
          font-size: 20px;
        }
      }

      &.active i,
      &.active a {
        color: $reactify-theme-color-1;
      }
    }
  }
}
